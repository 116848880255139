import { Alert, Snackbar } from '@mui/material'
import React, { FC } from 'react'


export interface SnackBarProps {
    isOpen: boolean,
    setOpen: any,
    message: string,
}

const SnackBar: FC<SnackBarProps> = ({ isOpen, setOpen, message }) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isOpen} autoHideDuration={5000} onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default React.memo(SnackBar)