import { useQuery } from '@tanstack/react-query';
import { db } from 'config/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { BsClock } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
    const getBlogs=async()=>{
        const colSnap:any=await getDocs(collection(db,"Blogs"))
        const arr:any=[];
        colSnap.forEach((blogSnap:any)=>{
            arr.push({...blogSnap.data(),id:blogSnap.id})
        })
        return arr;
    }
    const {data:blogs,loading}:any=useQuery(['blogs'],getBlogs)
    const navigate=useNavigate()
    return (
        <div className="overflow-hidden relative">
            <Helmet>
                <title>Blogs</title>
            </Helmet>
            <div className='max-h-[60vh] overflow-hidden relative flex items-center'>
                <img src="https://wallpaperaccess.com/full/6883074.jpg" alt="" loading='lazy' className='w-full' />
                <div className='absolute text-lg sm:text-3xl lg:text-4xl 2xl:text-5xl font-extrabold capitalize ml-[3.5%]'>
                    <div className='bg-white p-[10px] sm:px-[15px] sm:py-[10px] mb-[1px] w-fit'>
                        Destinations that are meant to 
                    </div>
                    <div className='bg-white p-[10px] sm:px-[15px] sm:py-[10px] w-fit'>give incredible holiday</div>
                    <div className='bg-white p-[10px] sm:px-[15px] sm:py-[10px] w-fit'>experiences</div>
                </div>
            </div>
            <div className='w-[93%] mx-auto'>
                <div className='my-[50px] grid md:grid-cols-[1fr_1fr] lg:grid-cols-[1fr_1fr_1fr] 2xl:grid-cols-[1fr_1fr_1fr_1fr] gap-[20px] sm:gap-[30px] lg:gap-[20px] xl:gap-[30px]'>
                {blogs&&blogs.map((blog:any)=>{
                    return <div onClick={()=>{
                        console.log(blog?.slug[0],"blogs")
                        navigate(`/blogs/${blog?.slug[0]}`)
                    }} className='h-[270px] md:h-[300px] 2xl:h-[320px] relative cursor-pointer'>
                            <div className='absolute z-20 text-xs 2xl:text-sm drop-shadow-md font-medium text-white top-0 left-[10px] translate-y-[-50%] bg-gradient-to-b from-red-500 to-red-800 rounded-b-md px-[10px] py-[5px]'>
                                Category
                            </div>
                        <div className='h-[60%] overflow-hidden object-cover relative'>
                            <img src={blog?.imgUrl} alt={blog?.title} loading='lazy' className=''/>
                        </div>
                        <div className='h-[40%] border border-gray-300 border-t-0 p-[15px]'>
                            <div className='font-bold text-sm sm:text-base md:text-sm 2xl:text-base capitalize'>{blog?.title}</div>
                            <div className='text-gray-600 my-[10px] text-xs sm:text-sm md:text-xs 2xl:text-sm flex gap-[10px] flex-wrap'>
                                <span>{blog?.author}</span>
                                <span className='flex items-center gap-[5px]'><BsClock/>{moment(blog?.createdAt?.toDate()).format("MMM DD, YYYY")}</span>
                            </div>
                        </div>
                    </div>
                })}
                </div>
            </div>
        </div>
    )
}

export default Blog