import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage'


const firebaseConfig = {
    apiKey: "AIzaSyDs7y9ectgdjj_RLJ6JXXFWiiIPddWctoY",
    authDomain: "bwi-aerotrav-dev.firebaseapp.com",
    projectId: "bwi-aerotrav-dev",
    storageBucket: "bwi-aerotrav-dev.appspot.com",
    messagingSenderId: "22736443466",
    appId: "1:22736443466:web:9e8c13b7981bf9d1e9aeb3",
    measurementId: "G-KYXZZY36WF"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app)
