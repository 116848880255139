import { combineReducers } from "@reduxjs/toolkit";
import { searchReducerFile } from "./searchReducer"
import { flightsReducer } from "./flightsReducer"
import { userReducer } from "./userReducer"
import { appReducer } from "./appReducer"
import { persistReducer } from 'redux-persist';
import { filterReducer } from "./filterReducer";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

export const persistConfig = {
    key: 'searchReducer',
    storage,
}

// export const searchReducer = persistReducer(persistConfig, searchReducerFile)

const reducers = combineReducers({
    searchReducer: searchReducerFile,
    flightsReducer,
    appReducer,
    userReducer,
    filterReducer
})

export default reducers