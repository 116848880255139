import { UserActionTypes } from "app/constants/userActionTypes"
// export const userLogin = (payload) => {
//     return async (dispatch) => {
//         const response = await fetch("https://test-portal-339608.as.r.appspot.com/Login_Signup/studentLogin/", {
//             method: "POST",
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(payload)
//         })
//         const res = await response.json();
//     }
// }


export const getCardDetails = (card) => {
    return {
        type: UserActionTypes.CARD_DETAILS,
        payload: card,
    }
}
export const foundUser = (user) => {
    console.log({ user });
    return {
        type: UserActionTypes.USER_FOUND,
        payload: user,
    }
}

export const removeUser = () => {
    return {
        type: UserActionTypes.REMOVE_USER,
    }
}
export const getBookings = (bookings) => {
    return {
        type: UserActionTypes.USER_BOOKINGS,
        payload: bookings
    }
}

export const getUserLocation = (location) => {
    console.log('inside reducer');
    return {
        type: UserActionTypes.USER_LOCATION,
        payload: location
    }
}
