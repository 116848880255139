import { UserActionTypes } from "app/constants/userActionTypes"

const initialState = {
    cardDetails: {
        cardNumber: '',
        expireDate: '',
        cvv: '',
        cardHolderName: ''
    },
    user: null,
    bookings: null,
    userLocation: null
}

export const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UserActionTypes.CARD_DETAILS:
            return {
                ...state,
                cardDetails: action.payload
            }
        case UserActionTypes.USER_FOUND:
            return {
                ...state,
                user: action.payload
            }
        case UserActionTypes.REMOVE_USER:
            return {
                ...state,
                user: null,
                bookings: null,
            }
        case UserActionTypes.USER_BOOKINGS:
            return {
                ...state,
                bookings: action.payload
            }
        case UserActionTypes.USER_LOCATION:
            // console.log('payload', action.payload);

            return {
                ...state,
                userLocation: action.payload
            }
        default:
            return state
    }
}