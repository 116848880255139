import { Backdrop } from "@mui/material";
// import Popup1 from "components/IntroductionPopUp/Popup1";
// import Popup2 from "components/IdlePopup/Popup2";
import React, { lazy, Suspense, useCallback, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useDispatch, useSelector } from "react-redux";
import MyRouter from "routers/index";
import { getSettings, getTokenId } from "services/firebaseQueries";
import { BiCookie } from "react-icons/bi";
import { getExchangedPrice } from "services/currencyExchange";
import { useIdleTimer } from 'react-idle-timer'
import { openSecondPopup } from "./app/actions/appActions";
import Loading from "shared/Loading/Loading";
import { install } from 'ga-gtag';
import { websiteTrafficConversion } from "googleAnalytics/websiteTrafficConversion";
import PriceChangedPopup from "components/PriceChangedPopup/PriceChangedPopup";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Popup1 = lazy(() => import("components/IntroductionPopUp/Popup1"))
const Popup2 = lazy(() => import("components/IdlePopup/Popup2"))

function App() {
  install('AW-11100115796');
  const dispatch = useDispatch();
  // const curren: any = curr
  const { isFirstPopUpOpen, currency, isSecondPopUpOpen } = useSelector((state: any) => state.appReducer)


  const getAuthToken = useCallback(async () => {
    let token = await getTokenId();
    // import('./app/actions/searchActions').then(searchActions => dispatch(searchActions.getTokenId(token[`${currency?.country}`])))
    import('./app/actions/searchActions').then(searchActions => dispatch(searchActions.getTokenId(token?.IN)))
    let settings = await getSettings()
    import('./app/actions/appActions').then(searchActions => dispatch(searchActions.getSettings(settings)))

  }, [currency])

  // const { userLocation } = useSelector((state: any) => state.userReducer)
  // const { searchData } = useSelector((state: any) => state.searchReducer)

  // useEffect(() => {
  //   TagManager.initialize({ gtmId: 'GTM-PD2Z66M' });
  // }, []);

  // useEffect(() => {
  //   analytics(window, document, 'script', 'dataLayer', 'GTM-PD2Z66M');
  // }, [])

  const timer = useIdleTimer({
    timeout: 1000 * 60 * 15,
    // timeout: 5000,
    element: document,
    startOnMount: true,
    onIdle: handleIdle
  })


  function handleIdle() {
    if (!window.location.pathname.includes('itinerary') && window.location.pathname !== '/FlightSearch') dispatch(openSecondPopup())
  }
  //  console.log(window.location.pathname);

  useEffect(() => {
    websiteTrafficConversion()
    if (window.location.pathname.includes('FlightSearch') || window.location.pathname.includes('pay-done')||window.location.pathname==="/fare-rule/"|| window.location.pathname.includes('blog')) {
      import('./app/actions/appActions').then((action: any) => dispatch(action.closeFirstPopup()))
    }
  }, []);



  useEffect(() => {
    getAuthToken()
  }, [currency])



  useEffect(() => {
    getForexValue()
    if (!window.location.pathname.includes('fare-rule') &&!window.location.pathname.includes('FlightSearch') && !window.location.pathname.includes('itinerary') && !window.location.pathname.includes('pay-done') && !window.location.pathname.includes('blog')) {
      setTimeout(() => {
        if (!window.location.pathname.includes('FlightSearch') && !window.location.pathname.includes('itinerary') && !window.location.pathname.includes('pay-done')&& !window.location.pathname.includes('blog')) {
          import('./app/actions/appActions').then((action: any) => dispatch(action.openFirstPopup()))
        }
      }, 15000);
    }
  }, []);

  let ele = document.querySelector("body");

  const showModal = () => {
    if (isFirstPopUpOpen) {
      // Disable scroll
      ele!.style.overflow = "hidden";
    } else {
      // Enable scroll
      ele!.style.overflow = "auto";
    }
  };

  useEffect(() => {
    showModal()
  }, [isFirstPopUpOpen])

  const getForexValue = async () => {
    try {
      let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
      let countryData = await data.json();
      import('./app/actions/userActions').then(action => dispatch(action.getUserLocation(countryData)))
      if (currency) {
        const res = await getExchangedPrice({ from: 'USD', to: currency?.name })
        import('app/actions/appActions').then((action: any) => dispatch(action.forexValue(res)))
      } else {
        import('app/actions/appActions').then((action: any) => dispatch(action.forexValue(1)))
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (currency?.name) {
      getForexValue()
    }
  }, [currency])

  // if (!searchData.TokenId || !userLocation) return <Loading />

  return (
    <Suspense fallback={<Loading />}>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <CookieConsent
          location="bottom"
          buttonText="Understood"
          cookieName="aerotrav.com"
          expires={150}
          hideOnAccept
          containerClasses="cookie-container"
          contentClasses="cookie-content"
          buttonWrapperClasses="cookie-btn-container"
          buttonClasses="cookie-btn"
          onAccept={() => { console.log('accepted') }}
        >
          <div className={`cookie`}>
            <BiCookie className="sm:text-7xl text-5xl" />
            <div className={`cookie-text`}>
              <h2 className="text-2xl font-bold">We use cookies</h2>
              <p className="">By using this website, you automatically accept that we use cookies 2034.</p>
            </div>
          </div>
        </CookieConsent>
        <Backdrop
          style={{ zIndex: '50' }}
          open={isFirstPopUpOpen}
        >
          <Suspense fallback={<Loading />} >
            <Popup1 />
          </Suspense>
        </Backdrop>
        <Backdrop
          style={{ zIndex: '50' }}
          open={isSecondPopUpOpen}
        >
          <Suspense fallback={<Loading />} >
            <Popup2 />
          </Suspense>
        </Backdrop>

        {/* <Backdrop
        style={{ zIndex: '40' }}
        open={true}
      >
        <Suspense fallback={<Loading />} >
        <Popup2/>
        </Suspense>
      </Backdrop> */}
<ToastContainer/>
        <MyRouter />
      </div>
    </Suspense>
  );
}

export default App;
