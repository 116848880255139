import React, { useState, useEffect } from 'react'

import NcImage from "shared/NcImage/NcImage";
import { TbArrowsRightLeft } from "react-icons/tb";
import moment from "moment";
import "./ExclusiveDeals.css"
import { doc, getDoc } from 'firebase/firestore'
import { db } from 'config/firebase-config'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CallNow from 'containers/OffersPage/CallPopUp/CallNow';
// import Loading from 'shared/Loading/Loading';
import Loading from 'shared/Loading/Loading';
import { gtag_report_conversion, gtag_report_conversion_callBack } from './../../../../googleAnalytics/googleAds'




const ExclusiveDeals = () => {
  const [offersData, setOffersData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const[showcard , setshowcard] = useState<Boolean>(true)
  const [call, setCall] = useState<boolean>(false)
  const [urlSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { currency } = useSelector((state: any) => state.appReducer)

  let data2: any

  const getoffersData = async () => {
    let documentSnapshots;
    documentSnapshots = await getDoc(
      doc(db, "Offers", "page")
    );
    data2 = documentSnapshots.data()
    setOffersData(data2)
  }
  // console.log(window.location.href,"href")


  function getData() {
    // console.log(window.location.href,"href")
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let curr2 = window.location.href;
        // console.log("currrrr" ,curr2, data2);
        
        let str = curr2.split('=');
        if (str[1] === 'INR') {
          resolve(data2?.IN);
        } else if (str[1] === 'USD') {
          resolve(data2?.US);
        } else {
          setshowcard(false)
          reject("Unsupported currency");
        }
      }, 1000);
    });
  }


  const getdata2 = () => {
    getData()
      .then(ress => {
        // console.log("data"  ,ress);

      
        setData(ress)
    // data?.cheapAirlineDeals?.forEach((item:any)=>{

    //     if(typeof(item.price)==='string'){
    //        item.price = parseFloat(item?.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    //     }else if (typeof(item.price)==='number'){
    //       item.price  = item?.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    //     }else {
    //         item.price= ''
    //     }
    //     // typeof(item.price)==='string' ?  parseFloat(item?.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }): item?.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

    //   })
        
        return data
      })
      .catch(error => {
      });
  }
  // const checkVar=()=>{
  //   if(typeof item?.price==="string"){

  //   }
  // }


  useEffect(() => {
    const fetchData = async () => {
      await getoffersData();
      getdata2();
    };
  
    fetchData();
  }, []);
  return (
    <>
    {/* <Loading></Loading> */}
      {
        showcard ?
          <div className={` relative px-[3.5%]  my-[20px] `}>
            <div className=" flex justify-center items-center lg:text-[38px] md:text-[30px] sm:text-[28px] text-[26px] font-semibold mb-[30px] text-center">
              Exclusive Round Trip Travel Deals…
            </div>
            <div className='exclusive-deals-style w-full'>
              {data ?
                <>
                  {
                    data?.cheapAirlineDeals?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className={`rounded-md overflow-hidden w-full`}
                        data-nc-id=""
                      >
                        <div className="">
                          <img src={item.imgUrl} className=" w-full h-[180px] " loading="lazy" />
                        </div>
                        <div className=" flex justify-between">
                          <div className="w-full">
                            <div className="py-[10px] font-semibold text-xl items-center flex">
                              {item.from}<TbArrowsRightLeft className="mx-[5px]" />{item.to}
                            </div>
                            <div className="flex gap-1 items-center justify-between relative mb-[10px] ">
                              <div className="w-[49%] text-base xl:text-xl 2xl:text-2xl font-semibold text-green-700 border-r border-slate-600 border-dotted">
                                &#8377;
                                {
                                  typeof item.price === 'string'
                                  ? parseFloat(item?.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                  : typeof item.price === 'number'
                                    ? item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    : item.price
                                }*
                              </div>
                              <a
                                href={`tel:${currency?.country === "IN" ? "+91 011 420 77777 " : '+1 8018827740'}`}>
                                <div className='cursor-pointer w-fit text-white px-[10px] py-[5px] bg-[#F70F36] hover:bg-red-700 rounded-md sm:text-[15px] text-[12px]'
                                >BOOK NOW
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </> : <Loading></Loading>
              }
            </div >
            <div className="pt-[10px] text-center mt-6 lg:text-base sm:text-sm text-xs">
              <span className="text-[#f70f36]">*</span>All Fares are Round Trip. Fares include all fuel surcharges, taxes & fees. Displayed fares are based on historical data, are subject to change and cannot be guaranteed until ticketed.
            </div>
          </div > : null
      }

    </>
  )
}

export default ExclusiveDeals