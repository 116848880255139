import React, { useEffect, useState } from "react";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { FocusedInputShape, SingleDatePicker } from "react-dates";
import RentalCarDatesRangeInput from "components/HeroSearchForm/RentalCarDatesRangeInput";
import { FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import moment from "moment";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
// import styles from './styles/flightSearchForm.module.css';
import typeSenseClient from "../../../../config/typesense"
import { FlightCabinClass, JourneyType } from "utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { BsPlus } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { useWindowSize } from "react-use";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { searchFlights } from "services/flightRequests";
import { slice } from 'lodash'
import { getUrlSearchParams } from "utils/urlSearchParams";
import Checkbox from "shared/Checkbox/Checkbox";
import { BsSearch } from "react-icons/bs";
import { airlineCodes } from "services/airlineCodes";
import OutsideClickHandler from "utils/OutsideClickHandler";
import { googleLinkConversions } from "googleAnalytics/googleLinkConversions";
import { useMediaQuery } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";
import "./FlightSearch.css"
import { httpsCallable } from "firebase/functions";
import { functions } from "config/firebase-config";
import {BsTelephoneFill} from "react-icons/bs"
import {BsTelephone} from "react-icons/bs"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BiPhone} from 'react-icons/bi'
import {VscStarFull} from "react-icons/vsc"


export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

export interface FlightSearchFormProps {
  haveDefaultValue?: boolean;
  isFullWidth?: boolean;
  handleClick?: Function;
  setIsFlightsLoading?: any,
}

const flightClass = [
  // {
  //   value: FlightCabinClass.All,
  //   name: "All",
  // },
  {
    value: FlightCabinClass.Economy,
    name: "Coach",
  },
  {
    value: FlightCabinClass.PremiumEconomy,
    name: "Premium Economy",
  },
  {
    value: FlightCabinClass.Business,
    name: "Business",
  },
  {
    value: FlightCabinClass.First,
    name: "First",
  },
];
const flightClassIndia = [
  {
    value: FlightCabinClass.Economy,
    name: "Economy",
  },
  {
    value: FlightCabinClass.PremiumEconomy,
    name: "Premium Economy",
  },
  {
    value: FlightCabinClass.Business,
    name: "Business",
  },
  {
    value: FlightCabinClass.First,
    name: "First",
  },
];

const travellersDetails = [
  {
    label: 'Adult',
    age: '16-64'
  },
  {
    label: 'Senior',
    age: '65+'
  },
  {
    label: 'Child',
    age: '2-15'
  },
  {
    label: 'Infant',
    age: 'under 2'
  },
]

const cabin: any = {
  "1": 'All',
  "2": "Coach",
  "3": 'Premium Economy',
  "4": 'Business',
  "5": 'Premium Business',
  "6": "First"
}
const cabinIndia: any = {
  "1": 'All',
  "2": "Economy",
  "3": 'Premium Economy',
  "4": 'Business',
  "5": 'Premium Business',
  "6": "First"
}



const FlightSearch: FC<FlightSearchFormProps> = ({ haveDefaultValue, isFullWidth = false, handleClick, setIsFlightsLoading }) => {
  const searchActions = import('./../../../../app/actions/searchActions').then(searchAction => searchAction)
  const [urlSearchParams]: any = useSearchParams()
  const matches = useMediaQuery('(max-width:550px)');
  const sendMail = httpsCallable(functions, 'contactUs-onOffersPageCall');


  const { searchData, passengersData, datesRangeData, coach } = useSelector((state: any) => state.searchReducer);
  const { currency } = useSelector((state: any) => state.appReducer);
  const { userLocation } = useSelector((state: any) => state.userReducer);
  const windowSize = useWindowSize();

  // DEFAULT DATA FOR ARCHIVE PAGE
  const defaultPickUpInputValue = urlSearchParams.get('from') || urlSearchParams.get('from1') || searchData.Segments[0].Origin;
  const defaultDropOffInputValue = urlSearchParams.get('to') || urlSearchParams.get('to1') || searchData.Segments[0].Destination;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // const [multiCity, setMultiCity] = useState<boolean>(false);

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: urlSearchParams.get('depart') || searchData.Segments[0]?.PreferredDepartureTime || moment(),
    endDate: urlSearchParams.get('return') || searchData.Segments[1]?.PreferredDepartureTime || moment().add({ day: 1 }),
  });

  const [mobileSizeFormOpen, setMobileSizeForm] = useState(false)

  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: "00:00",
    endTime: "00:00",
  });
  const [pickUpInputValue, setPickUpInputValue] = useState(defaultPickUpInputValue || "");
  const [dropOffInputValue, setDropOffInputValue] = useState(defaultDropOffInputValue || "")
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | null
  >(null);
  const [dropOffLocationType, setDropOffLocationType] = useState<JourneyType>(urlSearchParams.get('triptype') || searchData.JourneyType);
  const [guests, setGuests] = useState(0);
  const [guestDetails, setGuestDetails] = useState({
    infant: parseInt(urlSearchParams.get('infs')) || passengersData.infant,
    child: parseInt(urlSearchParams.get('chd')) || passengersData.child,
    adult: parseInt(urlSearchParams.get('adt')) || passengersData.adult,
    senior: parseInt(urlSearchParams.get('sen')) || passengersData.senior,
  })
  const [flightClassState, setFlightClassState] = useState({ name: '', value: searchData.Segments[0].FlightCabinClass });
  const [searchResults, setSearchResults] = useState(null);
  const [phoneNo,setPhoneNo]=useState<any>("")

  const [searchValue, setSearchValue] = useState<any>('')

  const [multiCityDates, setMultiCityDates] = useState<any>({
    "0": {
      date: moment(),
      focused: false,
    },
    "1": {
      date: moment(),
      focused: false,
    },
  })

  const phoneNoError=()=>{
    if(!phoneNo){
    toast.error("Please enter phone number")

    }
  
  }

  // USER EFFECT
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue({
        startDate: urlSearchParams.get('depart') || datesRangeData.arrival || moment(),
        endDate: urlSearchParams.get('return') || datesRangeData.depart || moment(),
      });
      setPickUpInputValue(defaultPickUpInputValue);
      setDropOffInputValue(defaultDropOffInputValue);
    }
  }, []);
  //'


  const updateMultiCityDates = () => {
    let multiCityDate = {};
    searchData?.Segments?.map((segment: any, idx: any) => {
      if (multiCityDates[idx]) {
        multiCityDate = { ...multiCityDate, [idx]: multiCityDates[idx] }
      } else {
        multiCityDate = {
          ...multiCityDate, [idx]: {
            date: moment(),
            focused: false,
          }
        }
      }
    })
    setMultiCityDates(multiCityDate)
  }


  useEffect(() => {
    updateMultiCityDates()

  }, [searchData])


  useEffect(() => {
    handleTotalTravllerValue()

  }, [guestDetails])

  const handleTotalTravllerValue = () => {
    searchActions.then(searchActions => dispatch(searchActions.getPassengerCount(guestDetails)));
    setGuests((val: any) => {
      return guestDetails.adult + guestDetails.child + guestDetails.infant + guestDetails.senior;;
    })
  }

  const handleTravellerChange = (name: string, type: string) => {
    switch (name) {
      case 'infant':
        if (type === 'inc') {
          if (guests === 9) return;
          return setGuestDetails((val: any) => {
            return { ...val, infant: val.infant + 1 }
          })
        } else {
          return setGuestDetails((val: any) => {
            return { ...val, infant: val.infant - 1 }
          })
        }
      case 'child':
        if (type === 'inc') {
          if (guests === 9) return;
          return setGuestDetails((val: any) => {
            return { ...val, child: val.child + 1 }
          })
        } else {
          return setGuestDetails((val: any) => {
            return { ...val, child: val.child - 1 }
          })
        }
      case 'adult':
        if (type === 'inc') {
          if (guests === 9) return;
          return setGuestDetails((val: any) => {
            return { ...val, adult: val.adult + 1 }
          })
        } else {
          if (guestDetails.adult === 1) return
          return setGuestDetails((val: any) => {
            return { ...val, adult: val.adult - 1 }
          })
        }
      case 'senior':
        if (type === 'inc') {
          if (guests === 9) return;
          return setGuestDetails((val: any) => {
            return { ...val, senior: val.senior + 1 }
          })

        } else {
          return setGuestDetails((val: any) => {
            return { ...val, senior: val.senior - 1 }
          })
        }
      default:
        break;
    }

  }


  const getClientData = async (value?: any) => {
    // let searchParametersByLocation = {
    //   'q': value,
    //   'query_by': 'airport_code,city_name,city_code,airport_name,country_name',
    //   'group_by': 'city_name',
    //   'per_page': 250
    // }

    let searchRequests = {
      'searches': [
        {
          'collection': 'airports',
          'q': value,
          'group_by': 'city_name',
          'per_page': 250,
          'filter_by': `country_code:=${userLocation?.country}`
        },
        {
          'collection': 'airports',
          'q': value,
          'group_by': 'city_name',
          'per_page': 250,
          'filter_by': `country_code:!=${userLocation?.country}`
        },
      ]
    }
    let commonSearchParams = {
      'query_by': 'city_name,airport_code,city_code,airport_name,country_name',
    }

    try {



      let client = await typeSenseClient();
      // let data = await client.collections('airports').documents().search(searchParameters)

      let data = await client.multiSearch.perform(searchRequests, commonSearchParams)
      let updatedResults: any = []
      updatedResults = [...(slice(data.results[0]?.grouped_hits, 0, 10)), ...(slice(data.results[1]?.grouped_hits, 0, 10))]

      // let IndiaRes = data.grouped_hits.filter(documents=>)
      setSearchResults(updatedResults);
    } catch (error) {
      console.log('err', error);

    }
  }


  // useEffect(() => {
  //   getClientData();
  // }, [pickUpInputValue, dropOffInputValue])


  const renderGuest = () => {
    return (
      <Popover className="h-full w-full rounded-md relative z-[700]">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                h-full flex flex-col p-[10px] justify-between items-start w-full rounded-md
           ${open ? "" : ""}
            `}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="font-normal xl:text-[28px] lg:text-[26px] md:text-[25px] sm:text-[23px] text-[21px]">{guests}</div>
              <div className="text-xs sm:text-sm lg:text-base xl:text-lg">{`${guests > 1 ? "Travellers" : "Traveller"}`}</div>
              {/* <ChevronDownIcon
                  className={`${open ? "" : "text-opacity-70"
                    } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 tran-y-1"
              enterTo="opacity-100 tran-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 tran-y-0"
              leaveTo="opacity-0 tran-y-1"
            >
              <Popover.Panel className={`absolute left-0 z-50 px-4 mt-3 sm:px-0 w-[300px] `}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                  {travellersDetails.map((val, idx) => {
                    // let value = guestDetails[val.label];
                    return <div key={idx} className="relative flex bg-white dark:bg-neutral-800 p-4 justify-between items-center">
                      <label className="text-base"  >{val.label} <span className="text-xs" >({val.age})</span></label>
                      <NcInputNumber
                        // label={val.label}
                        label={val.label}
                        onChange={(e) => setGuests(e)}
                        min={0}
                        currentValue={passengersData[val.label.toLowerCase()]}
                        defaultValue={passengersData[val.label.toLowerCase()]}
                        max={9}
                        handleTravellerChange={handleTravellerChange}
                      />
                    </div>
                  })}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span>{searchData.Segments[0].FlightCabinClass === '1' ? 'Coach' : currency && currency.country === "IN" ? (cabinIndia[`${searchData.Segments[0].FlightCabinClass}`]) : (cabin[`${searchData.Segments[0].FlightCabinClass}`])}</span>
                <ChevronDownIcon
                  className={`${open ? "" : "text-opacity-70"
                    } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 tran-y-1"
                enterTo="opacity-100 tran-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 tran-y-0"
                leaveTo="opacity-0 tran-y-1"
              >
                <Popover.Panel className="absolute z-[700] w-screen max-w-[220px] sm:max-w-[220px] px-4 mt-3 transform translate-x-[-50%] sm:translate-x-0 -tran-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {currency?.country === "IN" ? flightClassIndia.map((item) => (
                        <a
                          key={item.name}
                          href={'/##'}
                          onClick={(e) => {
                            e.preventDefault();
                            setFlightClassState({ name: item.name, value: item.value });
                            searchActions.then(searchActions => dispatch(searchActions.getCabinClass(item.value, item.name)))
                            close();
                          }}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <p className="text-sm font-medium ">{item.name}</p>
                        </a>
                      ))
                        : flightClass.map((item) => (
                          <a
                            key={item.name}
                            href={'/##'}
                            onClick={(e) => {
                              e.preventDefault();
                              setFlightClassState({ name: item.name, value: item.value });
                              searchActions.then(searchActions => dispatch(searchActions.getCabinClass(item.value, item.name)))
                              close();
                            }}
                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <p className="text-sm font-medium ">{item.name}</p>
                          </a>
                        ))
                      }
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" pt-3 pb-0 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${dropOffLocationType === JourneyType.Return
            ? "bg-black shadow-black/10 shadow-lg text-white"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => {
            setDropOffLocationType(JourneyType.Return);
            searchActions.then(searchActions => dispatch(searchActions.getJourneyType(JourneyType.Return)))
          }}
        >
          Round-trip
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${dropOffLocationType === JourneyType.OneWay
            ? "bg-black text-white shadow-black/10 shadow-lg"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => {
            setDropOffLocationType(JourneyType.OneWay)
            searchActions.then(searchActions => dispatch(searchActions.getJourneyType(JourneyType.OneWay)))
          }}
        >
          One-way
        </div>
        {/* <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${dropOffLocationType === JourneyType.MultiStop
            ? "bg-black text-white shadow-black/10 shadow-lg"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => {
            setDropOffLocationType(JourneyType.MultiStop)
            searchActions.then(searchActions => dispatch(searchActions.getJourneyType(JourneyType.MultiStop)))
          }}
        >
          Multi-City
        </div> */}
        <div className=" mr-2 my-1 sm:mr-4 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        {/* <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          // {renderGuest()}
        </div> */}
      </div>
    );
  };

  


  const removeCitySegment = (index: any) => {
    let arr = searchData.Segments.filter((segment: any, idx: any) => idx !== index)
    import('../../../../app/actions/searchActions').then(action => dispatch(action.getDeletedSegments(arr)))
  }
  const renderDate = (index: any) => {
    if (searchData.JourneyType === JourneyType.MultiStop) {
      if (urlSearchParams.get(`depart${index + 1}`)) {
        return <>{moment(new Date(urlSearchParams.get(`depart${index + 1}`))).format('DD MMM YYYY')}</>
      }
      if (!multiCityDates[index]?.date || multiCityDates[index]?.date.toDate().toString() === 'Invalid Date') {
        return <>
          {moment().format('DD MMM YYYY')}
        </>
      } else {
        return <>{moment(multiCityDates[index].date).format('DD MMM YYYY')}</>
      }
    }
  }

  const getClassName = (idx: any) => {
    if (multiCityDates[idx]?.focused) {
      return `nc-hero-field-focused border-[rgb(233, 230, 230)]`
    } else {
      return ""
    }
  }


  const renderInputpickUpDate = (index: any) => {
    // const focused = focusedInput === "startDate";
    return (
      <div
        className={`flex h-full p-[10px] justify-left items-center space-x-3 cursor-pointer${getClassName(index)}`}
      >
        {/* <div className="text-neutral-300 dark:text-neutral-400 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field -mt-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#F70F36"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div> */}
        <div className="flex flex-col items-start">
          {/* <div className="absolute inset-0" /> */}

          <div className=" inline-flex items-center text-lg xl:text-base font-semibold">
            <span className="flex-shrink-0 text-lg">

              {renderDate(index)}

            </span>
            {/* {stateDate.startDate && renderEditTime("pickUp")} */}
          </div>

          <span className="block mt-1 text-sm text-neutral-400 font-light leading-none">
            {dateRangeValue.startDate ? "Depart" : `Add date`}
          </span>
        </div>
      </div>
    );
  };

  const handleMultiDatesSelection = (date: any, index: any) => {
    let segments: any = [];
    searchData?.Segments?.map((segment: any, idx: any) => {
      if (index === idx) {
        segments.push({ ...segment, PreferredDepartureTime: moment(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString()?.slice(0, -5), PreferredArrivalTime: moment(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString()?.slice(0, -5) })
      } else {
        segments.push({ ...segment })
      }
    })
    import('./../../../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getDatesSegment(segments)))
  }


  const handleSearch = async (e: any) => {

    e.preventDefault()
    setIsFlightsLoading(true)
    import('./../../../../app/actions/searchActions').then(actions => dispatch(actions.endTimer()))
    const res = await searchFlights({ country: userLocation?.country, data: searchData });
    if (res?.status === 200) {
      import('./../../../../app/actions/searchActions').then(actions => dispatch(actions.getTraceId(res?.traceId)))
      import('./../../../../app/actions/flightActions').then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
      setIsFlightsLoading(false);
      import('./../../../../app/actions/searchActions').then(actions => dispatch(actions.startTimer()))
    } else {
      import('./../../../../app/actions/searchActions').then(actions => dispatch(actions.getTraceId(res?.traceId)))
      import('./../../../../app/actions/flightActions').then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
    }
  }


  // console.log({ multiCityDates });


  const renderMultiSearchForm = () => {
    const multiSearchFormDisabled = () => {
      for (let idx = 0; idx < searchData.Segments.length; idx++) {
        if (
          searchData.Segments[idx].Origin === ""
          ||
          searchData.Segments[idx].Destination === ""
          ||
          searchData.Segments[idx].Origin === searchData.Segments[idx].Destination
        ) return true;
      }
      return false;
    }
    return <>
      {searchData.Segments.length >= 5 ||
        <div
          onClick={() => {
            searchActions.then(action => dispatch(action.addSegment()));
          }}
          className="absolute right-4 bottom-2 z-30 hover:bg-secondary-6000 hover:cursor-pointer bg-primary-6000 rounded-full w-10 h-10 flex items-center justify-center text-white ">
          <BsPlus className="text-3xl" />
        </div>
      }
      {searchData?.Segments?.map((segment: any, idx: any) => {
        return <div key={idx} className="">
          <div className="flight-search-from-multi flight-search-from">
            <div className="flight-from-where m-[5px] border border-slate-400 rounded-md">
              <LocationInput
                isFullWidth={isFullWidth}
                index={idx === 0 ? "0" : idx}
                searchResults={searchResults}
                defaultValue={segment?.Origin || ''}
                isSource={true}
                isMultiCity
                getClientData={getClientData}
                onChange={(e) => {
                  setPickUpInputValue(e)
                  import('./../../../../app/actions/searchActions').then(action => dispatch(action.getPlaces({ type: 'source', value: e })))
                }}
                onInputDone={() => setFieldFocused("dropOffInput")}
                placeHolder="From where?"
                desc=""
              />
            </div>
            <div className="to-where m-[5px] border border-slate-400 rounded-md">
              <LocationInput
                isFullWidth={isFullWidth}
                index={idx === 0 ? "0" : idx}
                searchResults={searchResults}
                isSource={false}
                isMultiCity
                getClientData={getClientData}
                defaultValue={segment?.Destination || ''}
                onChange={(e) => {
                  setDropOffInputValue(e)

                }}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="To where?"
                desc=""
                autoFocus={fieldFocused === "dropOffInput"}
              />
            </div>
            <div className="m-[5px] date-container">
              <div className="w-full h-full ">
                <SingleDatePicker
                  noBorder
                  date={multiCityDates[idx]?.date}
                  onDateChange={(date: any) => {
                    setMultiCityDates({ ...multiCityDates, [idx]: { ...multiCityDates[idx], date: date, focused: false } })
                    handleMultiDatesSelection(date, idx)
                  }}
                  numberOfMonths={1}
                  reopenPickerOnClearDate
                  focused={multiCityDates[idx]?.focused}
                  onFocusChange={(e) => {
                    return
                  }}
                  id="your_unique_id"
                  daySize={windowSize.width > 1279 ? 54 : 44}
                  hideKeyboardShortcutsPanel
                />
              </div>
              <div className="h-full relative top-[-100%] depart border border-slate-400 rounded-md z-[10]"
                onClick={() => {
                  setMultiCityDates({ ...multiCityDates, [idx]: { ...multiCityDates[idx], focused: true } })
                }}
              >
                {renderInputpickUpDate(idx)}
              </div>
            </div>
            {idx > 1 &&
              <div className="flex items-center hover:bg-black h-fit w-fit z-10 rounded-xl" onClick={() => removeCitySegment(idx)}>
                <MdClear className="text-4xl hover:text-white hover:cursor-pointer" />
              </div>
            }
            {idx === 0 && <div className="guests m-[5px] border border-slate-400 rounded-md">
              {renderGuest()}
            </div>}
            {idx === 0 && < div className="search m-[5px] relative">
              <button
                // onClick={()=>{
                //   console.log("search btn clicked")
                // }}
                //    onClick={(e) => {
                //     if (isFullWidth) {
                //       e.preventDefault()
                //       navigate(`/FlightSearch${getUrlSearchParams(searchData, guestDetails.senior)}`)
                //       // handleSearch(e);
                //     } else {
                //       e.preventDefault()
                //       navigate(`/FlightSearch${getUrlSearchParams(searchData, guestDetails.senior)}`)
                //     }
                //   }}
                // disabled={multiSearchFormDisabled()}
                className='sm:text-base text-sm disabled:opacity-75 h-full w-full px-[20px] py-[5px] rounded-md bg-[#F70F36] hover:bg-red-700 flex items-center justify-center text-neutral-50 focus:outline-none'
              >
                <span>Search Flights</span>
                {/* <span className="">{urlSearchParams.get('triptype') ?
                  "Modify Search"
                  :
                  "Search Flights"
                }</span> */}
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg> */}
              </button>
            </div>}
          </div>

        </div>
      })
      }
    </>
  }


  const renderNormalForm = () => {
    return <div className="rounded-full  ">
     
      <div className={`${searchData.JourneyType === JourneyType.Return ? "flight-search-from-round" : "flight-search-from"} `}>
        <div className="flight-from-where m-[5px] border border-slate-400 rounded-md">
          <LocationInput
            isFullWidth={isFullWidth}
            searchResults={searchResults}
            defaultValue={pickUpInputValue?.toUpperCase()}
            isSource={true}
            getClientData={getClientData}
            onChange={(e) => {
              setPickUpInputValue(e)
              import('./../../../../app/actions/searchActions').then(action => dispatch(action.getPlaces({ type: 'source', value: e?.toUpperCase() })))
            }}
            onInputDone={() => setFieldFocused("dropOffInput")}
            placeHolder="From where?"
            desc=""
          /></div>
        <div className="to-where m-[5px] border border-slate-400 rounded-md">
          <LocationInput
            isFullWidth={isFullWidth}
            searchResults={searchResults}
            isSource={false}
            getClientData={getClientData}
            defaultValue={dropOffInputValue?.toUpperCase()}
            onChange={(e) => {
              setDropOffInputValue(e)
              import('./../../../../app/actions/searchActions').then(action => dispatch(action.getPlaces({ type: 'destination', value: e?.toUpperCase() })))
            }}
            onInputDone={() => setFieldFocused("startDate")}
            placeHolder="To where?"
            desc=""
            autoFocus={fieldFocused === "dropOffInput"}
          />
        </div>
        <div className="m-[5px] date-container">
          <RentalCarDatesRangeInput
            handleClick={handleClick}
            isFlight={true}
            dropOffLocationType={dropOffLocationType}
            setIsFlightsLoading={setIsFlightsLoading}
            defaultDateValue={dateRangeValue}
            isFullWidth={isFullWidth}
            defaultTimeValue={timeRangeValue}
            defaultFocus={
              fieldFocused === "dropOffInput" ? null : fieldFocused
            }
            onFocusChange={(focus) => {
              setFieldFocused(focus)
            }
            }
            onChange={(data) => {
              // console.log(data.stateDate.startDate?.toDate());
              setDateRangeValue(data.stateDate);
              searchActions.then(actions => dispatch(actions.getDates(data.stateDate)))
              setTimeRangeValue(data.stateTimeRage);
            }}
            className="flex-1"
            buttonSubmitHref={`/FlightSearch`}
          />
        </div>
        <div className="guests m-[5px] border border-slate-400 rounded-md relative z-10">
          {renderGuest()}
        </div>
        <div className={`flex items-center m-[5px]  px-[15px] justify-center phone-container  relative  border border-slate-400 rounded-md`}>
          <BiPhone className="text-[#fa657e] font-bold h-[25px] w-[25px] "/>
          <input
            type="text"
            placeholder="Phone Number"
            value={phoneNo}
            onChange={(e: any) => {
              setPhoneNo(e.target.value)
            }}
            className=" outline-0 w-full search-btn "
            style={{ border: 'none', outline: 'none' }}
          />
          <div className="text-[red]  text-[25px] absolute right-0 top-0">*</div>
          </div>
        <div className="search m-[5px] relative">
          <a
      href={phoneNo? `tel:${currency?.country  === "IN" ? "+91 011 420 77777 " : '+1 8018827740'}` : '#'}
            // href={`tel:${currency?.country  === "IN" ? "+91 011 420 77777 " : '+1 8018827740'}`}
            className='text-sm sm:text-base disabled:opacity-75 h-full  px-[20px] py-[5px] rounded-md bg-[#F70F36] hover:bg-red-700 flex items-center justify-center text-neutral-50 focus:outline-none'
            onClick={async (e) => {
              phoneNoError()
              let data = {
                source: searchData?.Segments[0]?.Origin,
                destination: searchData?.Segments[0]?.Destination,
                journeyType: searchData?.JourneyType,
                cabinClass: searchData?.Segments[0]?.FlightCabinClass,
                from: searchData?.Segments[0]?.PreferredDepartureTime,
                to: searchData?.JourneyType == "2" ? searchData?.Segments[1]?.PreferredDepartureTime : searchData?.Segments[0]?.PreferredDepartureTime,
                adult: searchData?.AdultCount,
                child: searchData?.ChildCount,
                infant: searchData?.InfantCount,
                country: currency?.country,
                phoneNo:phoneNo
              };
              if(phoneNo){
              await sendMail(data);
              }
            }}
            
          >
            <span>Search Flights</span>
          </a>
        </div>
      </div>
      {/* <div className={` mt-2 pt-2 pb-2 flex flex-wrap flex-1 items-center gap-4 px-1 border-t`}> */}
        {/* <div>
          <Checkbox
            name={'Direct Flight'}
            label={`Direct Flights`}
            textStyle='text-base'
            defaultChecked={searchData?.DirectFlight === "true"}
            onChange={(checked) =>
              searchActions.then(action => dispatch(action.toggleDirectFlight()))
            }
          />
        </div> */}
      
      {/* </div> */}
    </div>
  }

  const renderForm = () => {
    return (
      
      <div className={`w-full m-auto z-[600] ${isFullWidth ? 'mt-[0]' : 'mt-[1em]'} `}>
        
        <form className={` max-w-[1280px] ${isFullWidth ? 'w-full' : 'w-11/12'} mx-auto relative ${isFullWidth ? 'mt-4' : 'mt-4'}  rounded-[10px] xl:rounded-[15px]  shadow-xl dark:shadow-2xl ${isFullWidth ? 'bg-white' : 'bg-white'} dark:bg-neutral-800 relative`}>
          {isFullWidth ? matches ? mobileSizeFormOpen ? renderRadioBtn() : null : renderRadioBtn() : renderRadioBtn()}
          <div className="flex flex-col gap-2 pb-2 px-2">
            {searchData.JourneyType === JourneyType.MultiStop ?
              renderMultiSearchForm()
              :
              isFullWidth ? matches ?
                mobileSizeFormOpen ?
                  <>
                    {renderNormalForm()}
                  </>

                  :
                  <button
                    onClick={() => setMobileSizeForm(true)}
                    className='text-sm sm:text-base disabled:opacity-75 h-full w-full px-[20px] py-[10px] rounded-md bg-[#F70F36] hover:bg-red-700 flex items-center justify-center text-neutral-50 focus:outline-none'
                  >
                    <span>Search Flights</span>
                    {/* <span className="font-semibold">
                      {urlSearchParams.get('triptype') ?
                        "Modify Search"
                        :
                        "Search Flights"
                      }
                    </span> */}
                  </button>
                :
                renderNormalForm()
                : renderNormalForm()
            }
          </div>
        </form >
        {matches ?
          mobileSizeFormOpen ? <>
            <div onClick={() => setMobileSizeForm(false)} className="flex justify-center mt-2">
              <AiFillCloseCircle className="text-3xl text-slate-400" />
            </div>
          </> : <></> : <></>}
      </div >

    );
  };

  return renderForm();
};

export default React.memo(FlightSearch);
