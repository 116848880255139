import { db } from "config/firebase-config"
import { doc, getDoc } from "firebase/firestore"

export const getTokenId = async () => {
    const token = await getDoc(doc(db, 'Token', 'tokenId'));
    return token.data().token;
}

export const getSettings = async () => {
    const settings = await getDoc(doc(db, "Settings", "appSettings"))
    return settings.data()
}