import React, { FC, useState } from 'react'
import "./newsLetter.css";
import circle1 from "../../../../images/newHome/circles1.png"
import circle2 from "../../../../images/newHome/circles2.png"
import { AiOutlineMail } from "react-icons/ai";
import { CircularProgress, useMediaQuery } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import SnackBar from 'shared/SnackBar/SnackBar';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from 'config/firebase-config';
import SubArrow from "../../../../images/newHome/subscribeArrow.png"
import Pluses from "../../../../images/newHome/pluses.png"


export interface NewsLetterProps {
    className?: any
}
const NewsLetter: FC<NewsLetterProps> = ({ className }) => {
    const matches = useMediaQuery('(max-width:500px)');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const subscribe = async () => {
        try {
            setLoading(true)
            await addDoc(collection(db, "NewsLetter"), { createdAt: serverTimestamp(), email: email });
            setOpen(true)
            setEmail('')
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <div className={`w-[93%] mx-auto pt-[60px] ${className}`}>
            <SnackBar isOpen={open} setOpen={setOpen} message="Subscribed!" />
            <div className='w-full h-[280px] sm:h-[318px] mx-auto relative bg-[#f4f0ff] news flex justify-center items-end'>
                <img src={circle1} className='absolute -bottom-[7em] -left-[2em]' alt="" />
                <img src={circle2} alt="" className='absolute  top-0 right-0' />
                <div className='flex flex-col mb-14 gap-11'>
                    <img src={SubArrow} className={`absolute sm:w-[68px] w-[50px] h-[50px] sm:h-[68px] top-[-25px] ${matches ? "right-[-10px]" : "right-[-25px]"}`} alt='arrow' />
                    <img src={Pluses} className="absolute right-0 rotate-90 translate-x-[70%] translate-y-[20%]" alt="" />
                    <h2 className={`text-center text-xs ${matches && "text-[11px]"} sm:text-sm md:text-base lg:text-lg font-semibold`}>Subscribe to get information, latest news and other <br /> interesting offers about Aerotrav</h2>
                    <div className='flex gap-2 sm:flex-row flex-col items-center'>
                        <div className='relative'>
                            <input type="text" className={`rounded-[10px] ${matches ? "h-[40px]" : "h-[50px]"} md:w-[484px] sm:w-[300px] w-[300px] pl-10`} value={email} onChange={((e: any) => setEmail(e.target.value))} placeholder='Email' />
                            <AiOutlineMail className={`absolute translate-y-1/2 text-xl text-gray-500 left-2 ${matches ? "top-0" : "top-1"}`} />
                        </div>
                        <button className={`${matches ? "h-[40px]" : "h-[50px]"} w-[300px] sm:w-fit bg-primary-6000 rounded-[10px] px-8 text-white hover:cursor-pointer z-10`} onClick={subscribe} disabled={email === '' || !isEmail(email)}>
                            {loading ?
                                <CircularProgress
                                    size={25}
                                    style={{ marginTop: '4px', color: '#fff' }}
                                />
                                :
                                "Subscribe"
                            }
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter