import React from 'react'
import "./Features.css"
import customer from "./../images/24-hours.png"
import discount from "./../images/discount.png"
import purse from "./../images/purse.png"

const data = [
  { image: customer, heading: "24/7 Customer", heading2: "Assistance", text: "Dedicated customer support ", text2: "within minutes" },
  { image: discount, heading: "Lowest ", heading2: "Fares", text: "Guaranteed lowest fares &", text2: "best discount" },
  { image: purse, heading: "Zero", heading2: "Convenience Fee", text: "0 convenience fee ", text2: "for your travel" }
]

const Features = () => {
  return (
    <div className='w-full p-[20px] px-[3.5%] my-[40px]'>
      <div className='features-cont w-[85%]   mx-auto '>
        {
          data.map((item, idx) => {
            return <div className='' key={idx} >
              <div className='flex gap-5'>
                <div><img src={item.image} style={{ height: "100px", width: "100px" }} /></div>
                <div className=''>
                  <h2 className='font-bold sm:text-xl text-md '>{item.heading}</h2>
                  <h2 className='font-bold sm:text-xl mb-[5px] text-md'>{item.heading2}</h2>
                  <div className='sm:text-sm text-xs'>{item.text}</div>
                  <div className='sm:text-sm text-xs'>{item.text2}</div>
                </div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default Features
