import { useQuery } from "@tanstack/react-query";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
// import { FlightCardProps } from "components/FlightCard/FlightCard";
import { FlightType } from "data/types";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getCategoryData } from "services/homeData";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import { getUrlSearchParams } from "utils/urlSearchParams";
import "./sectiongridcategorybox.css"
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbArrowsRightLeft } from "react-icons/tb";
import moment from "moment";
import { getExchangedPrice } from 'services/currencyExchange'
import { JourneyType } from "utils/enums";
import Loading from "shared/Loading/Loading";

export interface SectionGridCategoryBoxProps {
  categories?: FlightType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: FlightType[] = [
  {
    id: "1",
    price: "$4,100",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
    name: "Korean Air",
  },
  {
    id: "2",
    price: "$3,380",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
    name: "Singapore Airlines",
  },
  {
    id: "3",
    price: "$2,380",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/multi.png",
    name: "Philippine Airlines",
  },
  {
    id: "1",
    price: "$4,100",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
    name: "Korean Air",
  },
  {
    id: "2",
    price: "$3,380",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
    name: "Singapore Airlines",
  },
  {
    id: "1",
    price: "$4,100",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
    name: "Korean Air",
  },
  {
    id: "2",
    price: "$3,380",
    logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
    name: "Singapore Airlines",
  },
];


const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-auto",
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }
  const navigate = useNavigate()
  const { data }: any = useQuery(['homeData'], getCategoryData, { refetchInterval: 600 * (100) })
  const { searchData } = useSelector((state: any) => state.searchReducer);
  const [urlSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { currency } = useSelector((state: any) => state.appReducer)


  const handleSearch = (e: any, item: any) => {
    let newSeg: any;
    if (`${item?.journeyType}` === JourneyType.OneWay) {
      newSeg = [{
        ...searchData?.Segments[0],
        Origin: item?.origin.cityCode,
        Destination: item?.destination.cityCode,
        PreferredDepartureTime: `${moment(item?.from.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`,
        PreferredArrivalTime: `${moment(item.to.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`
      }]
    }
    if (`${item?.journeyType}` === JourneyType.Return) {
      newSeg = [
        {
          ...searchData?.Segments[0],
          Origin: item?.source?.cityCode,
          Destination: item?.destination?.cityCode,
          PreferredDepartureTime: `${moment(item.from.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`,
          PreferredArrivalTime: `${moment(item.from.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`
        },
        {
          ...searchData?.Segments[0],
          Origin: item?.destination?.cityCode,
          Destination: item?.source?.cityCode,
          PreferredDepartureTime: `${moment(item.to.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`,
          PreferredArrivalTime: `${moment(item.to.toDate()?.setUTCHours(0, 0, 0, 0)).toISOString().slice(0, -5)}`
        },
      ]
    }

    import('../../app/actions/searchActions').then(action => dispatch(action.getJourneyType(`${item?.journeyType}`)))
    import('../../app/actions/searchActions').then(action => dispatch(action.getDatesSegment(newSeg)))

    let searchQuery = {
      "EndUserIp": '192.168.10.10',
      "TokenId": `${searchData.TokenId}`,
      "AdultCount": `1`,
      "ChildCount": '0',
      "InfantCount": '0',
      "DirectFlight": 'false',
      "OneStopFlight": 'false',
      "JourneyType": `${item?.journeyType}`, // 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
      "PreferredAirlines": null,
      "Segments": newSeg,
      "Sources": null,
    }
    navigate(`/FlightSearch${getUrlSearchParams(searchQuery, "0", currency)}`)

  }

  function getData() {
    let curr = urlSearchParams.get('cur')
    switch (curr) {
      case "INR":
        return data?.IN
      case "CAD":
        return data?.CA
      case "USD":
        return data?.US
      default:
        return data?.IN
    }
  }

  if (!data) return <Loading></Loading>

  return (
    <div className={`nc-SectionGridCategoryBox relative px-[3.5%] ${className}`}>
      <div className=" flex justify-center items-center lg:text-[38px] md:text-[30px] sm:text-[28px] text-[26px] font-semibold mb-[30px] text-center">
        {/* Exclusive Round Trip Travel Deals… */}
        Exclusive Round Trip Travel Deals…
      </div>

      <div className={`grid airline-deals-style gap-5 sm:gap-6 md:gap-8`}>
        {data ?
          <>
            {
              getData()?.cheapAirlineDeals?.map((item: any, index: any) => (
                <Link
                  key={index}
                  to={'/FlightSearch'}
                  onClick={(e) => { e.preventDefault(); handleSearch(e, item); }}
                  className={`rounded-md overflow-hidden `}
                  data-nc-id=""
                >
                  <div className="h-[180px]">
                    <NcImage src={item.destination.imgUrl} containerClassName="h-full" loading="lazy" />
                  </div>
                  <div className=" flex justify-between">
                    <div className="w-full">
                      <div className="py-[10px] font-semibold text-xl items-center flex">
                        {item.source.name}<TbArrowsRightLeft className="mx-[5px]" />{item.destination.name}
                      </div>
                      <div className="flex gap-1 items-center relative">
                        <div className="w-[49%] text-base xl:text-xl 2xl:text-2xl font-semibold text-green-700 border-r border-slate-600 border-dotted">
                          {currency?.symbol}{parseFloat((item?.price).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} *

                        </div>
                        <div className="flex flex-col gap-1 relative w-[49%] pl-[10px]">
                          <div className="text-lg absolute border border-[#F70F36] text-[#F70F36] rounded-full w-fit hover:bg-red-200 right-0 top-0" onClick={(e) => handleSearch(e, item)}>
                            <MdKeyboardArrowRight />
                          </div>
                          <div className="flex gap-1 items-center text-xs font-medium">
                            <span className="">{item.source.cityCode}</span>
                            <TbArrowsRightLeft />
                            <span className="">{item.destination.cityCode}</span>
                          </div>
                          <div className="flex gap-1 items-center text-xs">
                            {moment(item?.from?.toDate()).format('DD MMM')} - {moment(item?.to?.toDate()).format('DD MMM')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            }
          </>
          : <Loading />
          // categories.map((item: FlightType, i: number) => (
          //   <CardComponentName key={i} data={{ id: item.id, logo: item.logo, name: item.name, price: item.price }} />
          // ))
        }
      </div>
      <div className="pt-[10px] text-center mt-6 lg:text-base sm:text-sm text-xs">
        <span className="text-[#f70f36]">*</span>All Fares are Round Trip. Fares include all fuel surcharges, taxes & fees. Displayed fares are based on historical data, are subject to change and cannot be guaranteed until ticketed.
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
