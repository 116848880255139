import React from 'react'
import { Link } from 'react-router-dom'

const BookingFailed = () => {
    return (
        <div className='min-h-[80vh] flex justify-center items-center flex-col gap-2'>
            <h1 className='font-bold text-3xl text-orange-500'>Booking Under Process!</h1>
            <p className='text-lg text-black'>Due to some reasons your booking is under processing. You will receive an email regarding the status of you booking soon.</p>
            <Link to="/" className='bg-primary-6000 py-2 px-5 rounded-md text-white'>Go to home</Link>
        </div>
    )
}

export default BookingFailed