export const airlineCodes = [
    {
        "AIRLINECODE": "0B",
        "AIRLINENAME": "Blue Air Transport Aerian"
    },
    {
        "AIRLINECODE": "0D",
        "AIRLINENAME": "Darwin Airline"
    },
    {
        "AIRLINECODE": "0V",
        "AIRLINENAME": "VIETNAM AIR SERVICE"
    },
    {
        "AIRLINECODE": "1B",
        "AIRLINENAME": "BH Air"
    },
    {
        "AIRLINECODE": "1X",
        "AIRLINENAME": "Branson Air Express"
    },
    {
        "AIRLINECODE": "2A",
        "AIRLINENAME": "DEUTSCHE BAHN AG"
    },
    {
        "AIRLINECODE": "2B",
        "AIRLINENAME": "Aerocondor"
    },
    {
        "AIRLINECODE": "2C",
        "AIRLINENAME": "SNCF"
    },
    {
        "AIRLINECODE": "2D",
        "AIRLINENAME": "Dynamic Airways"
    },
    {
        "AIRLINECODE": "2E",
        "AIRLINENAME": "Smokey Bay Air"
    },
    {
        "AIRLINECODE": "2F",
        "AIRLINENAME": "Frontier Flying Service"
    },
    {
        "AIRLINECODE": "2H",
        "AIRLINENAME": "European Airlines"
    },
    {
        "AIRLINECODE": "2I",
        "AIRLINENAME": " Peru"
    },
    {
        "AIRLINECODE": "2J",
        "AIRLINENAME": "Air Burkina"
    },
    {
        "AIRLINECODE": "2K",
        "AIRLINENAME": "AEROGAL"
    },
    {
        "AIRLINECODE": "2L",
        "AIRLINENAME": "Helvetic Airways"
    },
    {
        "AIRLINECODE": "2M",
        "AIRLINENAME": "Moldavian Airlines"
    },
    {
        "AIRLINECODE": "2N",
        "AIRLINENAME": "Next Jet"
    },
    {
        "AIRLINECODE": "2P",
        "AIRLINENAME": "Air Philippines"
    },
    {
        "AIRLINECODE": "2R",
        "AIRLINENAME": "VIA Rail"
    },
    {
        "AIRLINECODE": "2S",
        "AIRLINENAME": "Air Carnival"
    },
    {
        "AIRLINECODE": "2T",
        "AIRLINENAME": "Turbo Megha Airways Private Limited"
    },
    {
        "AIRLINECODE": "2V",
        "AIRLINENAME": "AMTRAK"
    },
    {
        "AIRLINECODE": "2W",
        "AIRLINENAME": "Welcome Air"
    },
    {
        "AIRLINECODE": "2Y",
        "AIRLINENAME": "Air Andaman"
    },
    {
        "AIRLINECODE": "2Z",
        "AIRLINENAME": "Passaredo Airlines"
    },
    {
        "AIRLINECODE": "3B",
        "AIRLINENAME": "Job Air"
    },
    {
        "AIRLINECODE": "3C",
        "AIRLINENAME": "Corporate Express Airlines"
    },
    {
        "AIRLINECODE": "3D",
        "AIRLINENAME": "Denim Air"
    },
    {
        "AIRLINECODE": "3E",
        "AIRLINENAME": "Air Choice One"
    },
    {
        "AIRLINECODE": "3F",
        "AIRLINENAME": "Pacific Airways"
    },
    {
        "AIRLINECODE": "3G",
        "AIRLINENAME": "Atlant-Soyuz"
    },
    {
        "AIRLINECODE": "3H",
        "AIRLINENAME": "Air Inuit"
    },
    {
        "AIRLINECODE": "3J",
        "AIRLINENAME": "JUBBA AIRWAYS"
    },
    {
        "AIRLINECODE": "3K",
        "AIRLINENAME": "Jet Asia Airways"
    },
    {
        "AIRLINECODE": "3L",
        "AIRLINENAME": "Intersky"
    },
    {
        "AIRLINECODE": "3M",
        "AIRLINENAME": "Silver Airways"
    },
    {
        "AIRLINECODE": "3N",
        "AIRLINENAME": "Air Urga"
    },
    {
        "AIRLINECODE": "3O",
        "AIRLINENAME": "AIR ARABIA MAROC"
    },
    {
        "AIRLINECODE": "3P",
        "AIRLINENAME": "Tiara Air"
    },
    {
        "AIRLINECODE": "3Q",
        "AIRLINENAME": "China Yunnan Airlines"
    },
    {
        "AIRLINECODE": "3R",
        "AIRLINENAME": "Avia Air"
    },
    {
        "AIRLINECODE": "3S",
        "AIRLINENAME": "Air Antilles"
    },
    {
        "AIRLINECODE": "3T",
        "AIRLINENAME": "Turan Air"
    },
    {
        "AIRLINECODE": "3U",
        "AIRLINENAME": "Sichuan Airlines"
    },
    {
        "AIRLINECODE": "3W",
        "AIRLINENAME": "Wanair"
    },
    {
        "AIRLINECODE": "3X",
        "AIRLINENAME": "Japan Air Commuter"
    },
    {
        "AIRLINECODE": "3Z",
        "AIRLINENAME": "Necon Air"
    },
    {
        "AIRLINECODE": "4A",
        "AIRLINENAME": "Royal Bengal Airline"
    },
    {
        "AIRLINECODE": "4B",
        "AIRLINENAME": "Olson Air Service"
    },
    {
        "AIRLINECODE": "4C",
        "AIRLINENAME": "Aires S.A."
    },
    {
        "AIRLINECODE": "4D",
        "AIRLINENAME": "Air Sinai"
    },
    {
        "AIRLINECODE": "4E",
        "AIRLINENAME": "Tanana Air"
    },
    {
        "AIRLINECODE": "4F",
        "AIRLINENAME": "Air City"
    },
    {
        "AIRLINECODE": "4G",
        "AIRLINENAME": "Shenzhen Airlines"
    },
    {
        "AIRLINECODE": "4H",
        "AIRLINENAME": "Fly"
    },
    {
        "AIRLINECODE": "4J",
        "AIRLINENAME": "Somon Air"
    },
    {
        "AIRLINECODE": "4K",
        "AIRLINENAME": "Kenn Borek"
    },
    {
        "AIRLINECODE": "4L",
        "AIRLINENAME": "EuroLine"
    },
    {
        "AIRLINECODE": "4M",
        "AIRLINENAME": "LAN Argentina"
    },
    {
        "AIRLINECODE": "4N",
        "AIRLINENAME": "Air North"
    },
    {
        "AIRLINECODE": "4O",
        "AIRLINENAME": "Interjet"
    },
    {
        "AIRLINECODE": "4P",
        "AIRLINENAME": "Business Aviation"
    },
    {
        "AIRLINECODE": "4Q",
        "AIRLINENAME": "SAFI AIRWAYS"
    },
    {
        "AIRLINECODE": "4R",
        "AIRLINENAME": "Orient Eagle Airways"
    },
    {
        "AIRLINECODE": "4S",
        "AIRLINENAME": "East West Air"
    },
    {
        "AIRLINECODE": "4T",
        "AIRLINENAME": "BELAIR AIRLINES"
    },
    {
        "AIRLINECODE": "4U",
        "AIRLINENAME": "Germanwings"
    },
    {
        "AIRLINECODE": "4V",
        "AIRLINENAME": "Voyageur Airways"
    },
    {
        "AIRLINECODE": "4W",
        "AIRLINENAME": "Warbelow Air"
    },
    {
        "AIRLINECODE": "4Z",
        "AIRLINENAME": "SOUTH AFRICAN AIRLINK"
    },
    {
        "AIRLINECODE": "5A",
        "AIRLINENAME": "Alpine Aviat"
    },
    {
        "AIRLINECODE": "5B",
        "AIRLINENAME": "Tie Aviation"
    },
    {
        "AIRLINECODE": "5C",
        "AIRLINENAME": "Nature Air"
    },
    {
        "AIRLINECODE": "5D",
        "AIRLINENAME": "Aerolitoral S.A. de C.V."
    },
    {
        "AIRLINECODE": "5E",
        "AIRLINENAME": "Base Airlines"
    },
    {
        "AIRLINECODE": "5f",
        "AIRLINENAME": "FLY ONE"
    },
    {
        "AIRLINECODE": "5H",
        "AIRLINENAME": "Fly540"
    },
    {
        "AIRLINECODE": "5J",
        "AIRLINENAME": "Cebu Pacific"
    },
    {
        "AIRLINECODE": "5K",
        "AIRLINENAME": "Odessa Airlines"
    },
    {
        "AIRLINECODE": "5L",
        "AIRLINENAME": "Aerosur"
    },
    {
        "AIRLINECODE": "5M",
        "AIRLINENAME": "Sibaviatrans"
    },
    {
        "AIRLINECODE": "5N",
        "AIRLINENAME": "Nordavia - Regional Airlines"
    },
    {
        "AIRLINECODE": "5O",
        "AIRLINENAME": "ASL Airlines France"
    },
    {
        "AIRLINECODE": "5P",
        "AIRLINENAME": "Penta Aerial Transports S\/A"
    },
    {
        "AIRLINECODE": "5Q",
        "AIRLINENAME": "BQB Líneas Aéreas"
    },
    {
        "AIRLINECODE": "5R",
        "AIRLINENAME": "IRS Aero"
    },
    {
        "AIRLINECODE": "5T",
        "AIRLINENAME": "Canadian North"
    },
    {
        "AIRLINECODE": "5U",
        "AIRLINENAME": "TAG - Transportes Aereos Guatemaltecos"
    },
    {
        "AIRLINECODE": "5V",
        "AIRLINENAME": "Lviv Airlines"
    },
    {
        "AIRLINECODE": "5W",
        "AIRLINENAME": "speed-alliance"
    },
    {
        "AIRLINECODE": "5Y",
        "AIRLINENAME": "Isles Scilly"
    },
    {
        "AIRLINECODE": "6A",
        "AIRLINENAME": "Aviacsa-Consorcio Aviaxsa"
    },
    {
        "AIRLINECODE": "6C",
        "AIRLINENAME": "Air Timor"
    },
    {
        "AIRLINECODE": "6E",
        "AIRLINENAME": "IndiGo"
    },
    {
        "AIRLINECODE": "6G",
        "AIRLINENAME": "Air Wales"
    },
    {
        "AIRLINECODE": "6H",
        "AIRLINENAME": "Israir"
    },
    {
        "AIRLINECODE": "6J",
        "AIRLINENAME": "Skyservice Airlinesd\/b\/a Roots Air"
    },
    {
        "AIRLINECODE": "6K",
        "AIRLINENAME": "Asian Spirit"
    },
    {
        "AIRLINECODE": "6L",
        "AIRLINENAME": "Aklak Air"
    },
    {
        "AIRLINECODE": "6N",
        "AIRLINENAME": "Trans Travel Airlines"
    },
    {
        "AIRLINECODE": "6Q",
        "AIRLINENAME": "Slovak Airlines"
    },
    {
        "AIRLINECODE": "6S",
        "AIRLINENAME": "Saudi Gulf Airlines"
    },
    {
        "AIRLINECODE": "6T",
        "AIRLINENAME": "Air Mandalay"
    },
    {
        "AIRLINECODE": "6U",
        "AIRLINENAME": "Air Ukraine"
    },
    {
        "AIRLINECODE": "6V",
        "AIRLINENAME": "Lignes Aeriennes Congolaises"
    },
    {
        "AIRLINECODE": "6W",
        "AIRLINENAME": "Saratov Airlines"
    },
    {
        "AIRLINECODE": "6Y",
        "AIRLINENAME": "SMARTLYNX AIRLINES"
    },
    {
        "AIRLINECODE": "7B",
        "AIRLINENAME": "Atlant - Souyuz Air"
    },
    {
        "AIRLINECODE": "7C",
        "AIRLINENAME": "JEJU AIR"
    },
    {
        "AIRLINECODE": "7D",
        "AIRLINENAME": "Donbass Eastern Ukranian Airlines"
    },
    {
        "AIRLINECODE": "7E",
        "AIRLINENAME": "Sylt Air"
    },
    {
        "AIRLINECODE": "7F",
        "AIRLINENAME": "Firstair"
    },
    {
        "AIRLINECODE": "7G",
        "AIRLINENAME": "Bellair"
    },
    {
        "AIRLINECODE": "7H",
        "AIRLINENAME": "Corendon"
    },
    {
        "AIRLINECODE": "7I",
        "AIRLINENAME": "Insel Air"
    },
    {
        "AIRLINECODE": "7J",
        "AIRLINENAME": "Tajik Air"
    },
    {
        "AIRLINECODE": "7K",
        "AIRLINENAME": "Kogalymavia"
    },
    {
        "AIRLINECODE": "7L",
        "AIRLINENAME": "Aerocaribbean S. A."
    },
    {
        "AIRLINECODE": "7M",
        "AIRLINENAME": "MAYAir"
    },
    {
        "AIRLINECODE": "7N",
        "AIRLINENAME": "PAWA DOMINICANA"
    },
    {
        "AIRLINECODE": "7O",
        "AIRLINENAME": "Galaxy Air"
    },
    {
        "AIRLINECODE": "7Q",
        "AIRLINENAME": "Air Venezuela"
    },
    {
        "AIRLINECODE": "7R",
        "AIRLINENAME": "Rusline"
    },
    {
        "AIRLINECODE": "7S",
        "AIRLINENAME": "Arctic Transportation Services"
    },
    {
        "AIRLINECODE": "7T",
        "AIRLINENAME": "Trans Cote"
    },
    {
        "AIRLINECODE": "7V",
        "AIRLINENAME": "Federal Airlines"
    },
    {
        "AIRLINECODE": "7W",
        "AIRLINENAME": "Wind Rose Aviation Company"
    },
    {
        "AIRLINECODE": "7Y",
        "AIRLINENAME": "European Aie Express"
    },
    {
        "AIRLINECODE": "7Z",
        "AIRLINENAME": "L B Limited"
    },
    {
        "AIRLINECODE": "8A",
        "AIRLINENAME": "Arctic Air"
    },
    {
        "AIRLINECODE": "8B",
        "AIRLINENAME": "Caribbean  Airlines"
    },
    {
        "AIRLINECODE": "8C",
        "AIRLINENAME": "Shanxi Airlines"
    },
    {
        "AIRLINECODE": "8D",
        "AIRLINENAME": "Volare Airlines"
    },
    {
        "AIRLINECODE": "8E",
        "AIRLINENAME": "Bering Air"
    },
    {
        "AIRLINECODE": "8F",
        "AIRLINENAME": "Fischer Air"
    },
    {
        "AIRLINECODE": "8G",
        "AIRLINENAME": "Angel Airlines"
    },
    {
        "AIRLINECODE": "8H",
        "AIRLINENAME": "Equaflight"
    },
    {
        "AIRLINECODE": "8I",
        "AIRLINENAME": "Insel Air Aruba"
    },
    {
        "AIRLINECODE": "8J",
        "AIRLINENAME": "Jet4You"
    },
    {
        "AIRLINECODE": "8L",
        "AIRLINENAME": "LUCKY AIR"
    },
    {
        "AIRLINECODE": "8M",
        "AIRLINENAME": "Myanmar Airways"
    },
    {
        "AIRLINECODE": "8N",
        "AIRLINENAME": "Nordkalottlkyg"
    },
    {
        "AIRLINECODE": "8O",
        "AIRLINENAME": "West Coast Air"
    },
    {
        "AIRLINECODE": "8P",
        "AIRLINENAME": "Pacific Coastal"
    },
    {
        "AIRLINECODE": "8Q",
        "AIRLINENAME": "Onur Air"
    },
    {
        "AIRLINECODE": "8R",
        "AIRLINENAME": "SOL Lineas Aereas"
    },
    {
        "AIRLINECODE": "8T",
        "AIRLINENAME": "Air Tindi"
    },
    {
        "AIRLINECODE": "8U",
        "AIRLINENAME": "Afriqiyah Airways"
    },
    {
        "AIRLINECODE": "8V",
        "AIRLINENAME": "Wrightair Svc"
    },
    {
        "AIRLINECODE": "8W",
        "AIRLINENAME": "Private Wings"
    },
    {
        "AIRLINECODE": "8Z",
        "AIRLINENAME": "Laser"
    },
    {
        "AIRLINECODE": "9A",
        "AIRLINENAME": "Visa Airways"
    },
    {
        "AIRLINECODE": "9B",
        "AIRLINENAME": "ACCESRAIL"
    },
    {
        "AIRLINECODE": "9C",
        "AIRLINENAME": "SPRING AIRLINES"
    },
    {
        "AIRLINECODE": "9D",
        "AIRLINENAME": "Toumai Air Tchad"
    },
    {
        "AIRLINECODE": "9E",
        "AIRLINENAME": "Express Airlines"
    },
    {
        "AIRLINECODE": "9F",
        "AIRLINENAME": "Euro"
    },
    {
        "AIRLINECODE": "9G",
        "AIRLINENAME": "Galaxy Airways"
    },
    {
        "AIRLINECODE": "9H",
        "AIRLINENAME": "DUTCH Antiles Express"
    },
    {
        "AIRLINECODE": "9I",
        "AIRLINENAME": "Alliance Air"
    },
    {
        "AIRLINECODE": "9J",
        "AIRLINENAME": "Pacific Island Aviation"
    },
    {
        "AIRLINECODE": "9K",
        "AIRLINENAME": "Cape Air"
    },
    {
        "AIRLINECODE": "9L",
        "AIRLINENAME": "Colgan Air"
    },
    {
        "AIRLINECODE": "9M",
        "AIRLINENAME": "Central Mountain Air"
    },
    {
        "AIRLINECODE": "9N",
        "AIRLINENAME": "Tropic Air"
    },
    {
        "AIRLINECODE": "9P",
        "AIRLINENAME": "Pelangi Airways"
    },
    {
        "AIRLINECODE": "9T",
        "AIRLINENAME": "Transwest Air"
    },
    {
        "AIRLINECODE": "9U",
        "AIRLINENAME": "Air Moldova"
    },
    {
        "AIRLINECODE": "9V",
        "AIRLINENAME": "AVIOR AIRLINES"
    },
    {
        "AIRLINECODE": "9W",
        "AIRLINENAME": "Jet Airways"
    },
    {
        "AIRLINECODE": "9Y",
        "AIRLINENAME": "Air Kazakstan"
    },
    {
        "AIRLINECODE": "A3",
        "AIRLINENAME": "Aegean Airlines"
    },
    {
        "AIRLINECODE": "A4",
        "AIRLINENAME": "Aerocon"
    },
    {
        "AIRLINECODE": "A5",
        "AIRLINENAME": "HOP!"
    },
    {
        "AIRLINECODE": "A6",
        "AIRLINENAME": "Air Alps Aviation"
    },
    {
        "AIRLINECODE": "A7",
        "AIRLINENAME": "Air Comet"
    },
    {
        "AIRLINECODE": "A8",
        "AIRLINENAME": "Aerolineas Paraguayas"
    },
    {
        "AIRLINECODE": "A9",
        "AIRLINENAME": "Georgian Airlines"
    },
    {
        "AIRLINECODE": "AA",
        "AIRLINENAME": "American Airlines"
    },
    {
        "AIRLINECODE": "AB",
        "AIRLINENAME": "Air Berlin"
    },
    {
        "AIRLINECODE": "AC",
        "AIRLINENAME": "Air Canada"
    },
    {
        "AIRLINECODE": "AD",
        "AIRLINENAME": "Azul Linhas Airline"
    },
    {
        "AIRLINECODE": "AE",
        "AIRLINENAME": "Mandarin Airlines"
    },
    {
        "AIRLINECODE": "AF",
        "AIRLINENAME": "Air France"
    },
    {
        "AIRLINECODE": "AG",
        "AIRLINENAME": "Air Contractors"
    },
    {
        "AIRLINECODE": "AH",
        "AIRLINENAME": "Air Algerie"
    },
    {
        "AIRLINECODE": "AI",
        "AIRLINENAME": "Air India"
    },
    {
        "AIRLINECODE": "AJ",
        "AIRLINENAME": "AeroContractors"
    },
    {
        "AIRLINECODE": "AK",
        "AIRLINENAME": "Airasia"
    },
    {
        "AIRLINECODE": "AL",
        "AIRLINENAME": "Astral Aviation"
    },
    {
        "AIRLINECODE": "AM",
        "AIRLINENAME": "Aeromexico Aeronaves De Mexico"
    },
    {
        "AIRLINECODE": "AN",
        "AIRLINENAME": "Ansett Australia"
    },
    {
        "AIRLINECODE": "AO",
        "AIRLINENAME": "Australian Airlines"
    },
    {
        "AIRLINECODE": "AP",
        "AIRLINENAME": "Air One"
    },
    {
        "AIRLINECODE": "AQ",
        "AIRLINENAME": "Aloha Airline"
    },
    {
        "AIRLINECODE": "AR",
        "AIRLINENAME": "Aerolineas Argentinas"
    },
    {
        "AIRLINECODE": "AS",
        "AIRLINENAME": "Alaska Airlines"
    },
    {
        "AIRLINECODE": "AT",
        "AIRLINENAME": "Royal Air Maroc"
    },
    {
        "AIRLINECODE": "AU",
        "AIRLINENAME": "Austral Lineas Aereas Sa"
    },
    {
        "AIRLINECODE": "AV",
        "AIRLINENAME": "AVIANCA"
    },
    {
        "AIRLINECODE": "AW",
        "AIRLINENAME": "Africa World Airlines"
    },
    {
        "AIRLINECODE": "AX",
        "AIRLINENAME": "American Connection"
    },
    {
        "AIRLINECODE": "AY",
        "AIRLINENAME": "Finnair"
    },
    {
        "AIRLINECODE": "AZ",
        "AIRLINENAME": "Alitalia"
    },
    {
        "AIRLINECODE": "B0",
        "AIRLINENAME": "LA COMPAGNIE"
    },
    {
        "AIRLINECODE": "B2",
        "AIRLINENAME": "Belavia"
    },
    {
        "AIRLINECODE": "B3",
        "AIRLINENAME": "Bhutan Airlines"
    },
    {
        "AIRLINECODE": "B4",
        "AIRLINENAME": "B.A.C.H Flugbetrieb"
    },
    {
        "AIRLINECODE": "B5",
        "AIRLINENAME": "East African Safari Air Express"
    },
    {
        "AIRLINECODE": "B6",
        "AIRLINENAME": "jetBlue"
    },
    {
        "AIRLINECODE": "B7",
        "AIRLINENAME": "Uni Airways"
    },
    {
        "AIRLINECODE": "B8",
        "AIRLINENAME": "ERITREAN AIRLINES"
    },
    {
        "AIRLINECODE": "B9",
        "AIRLINENAME": "Iran Air Tours"
    },
    {
        "AIRLINECODE": "BA",
        "AIRLINENAME": "British Airways"
    },
    {
        "AIRLINECODE": "BB",
        "AIRLINENAME": "Seaborne Aviation"
    },
    {
        "AIRLINECODE": "BC",
        "AIRLINENAME": "Skymark Airlines"
    },
    {
        "AIRLINECODE": "BD",
        "AIRLINENAME": "Flybmi"
    },
    {
        "AIRLINECODE": "BE",
        "AIRLINENAME": "Flybe"
    },
    {
        "AIRLINECODE": "BF",
        "AIRLINENAME": "Markair"
    },
    {
        "AIRLINECODE": "BG",
        "AIRLINENAME": "Biman Bangladesh Airlines"
    },
    {
        "AIRLINECODE": "BH",
        "AIRLINENAME": "Hawkair"
    },
    {
        "AIRLINECODE": "BI",
        "AIRLINENAME": "Royal Brunei Airlines"
    },
    {
        "AIRLINECODE": "BJ",
        "AIRLINENAME": "Nouvelair"
    },
    {
        "AIRLINECODE": "BL",
        "AIRLINENAME": "Pacific Airlines"
    },
    {
        "AIRLINECODE": "BM",
        "AIRLINENAME": "Aero Traporti"
    },
    {
        "AIRLINECODE": "BN",
        "AIRLINENAME": "Bahrain Air"
    },
    {
        "AIRLINECODE": "BO",
        "AIRLINENAME": "Bouraq Airlines"
    },
    {
        "AIRLINECODE": "BP",
        "AIRLINENAME": "Air Botswana"
    },
    {
        "AIRLINECODE": "BR",
        "AIRLINENAME": "Eva Airways"
    },
    {
        "AIRLINECODE": "BS",
        "AIRLINENAME": "British Intl"
    },
    {
        "AIRLINECODE": "BT",
        "AIRLINENAME": "Air Baltic"
    },
    {
        "AIRLINECODE": "BU",
        "AIRLINENAME": "Braathens ASA"
    },
    {
        "AIRLINECODE": "BV",
        "AIRLINENAME": "Blue Panorama Airlines"
    },
    {
        "AIRLINECODE": "BW",
        "AIRLINENAME": "Caribbean Airlines"
    },
    {
        "AIRLINECODE": "BX",
        "AIRLINENAME": "Air Busan"
    },
    {
        "AIRLINECODE": "BY",
        "AIRLINENAME": "Thomson Fly"
    },
    {
        "AIRLINECODE": "BZ",
        "AIRLINENAME": "Keystone Air Service"
    },
    {
        "AIRLINECODE": "C2",
        "AIRLINENAME": "CEIBA INTERCONTINENTAL"
    },
    {
        "AIRLINECODE": "C3",
        "AIRLINENAME": "Icar"
    },
    {
        "AIRLINECODE": "C5",
        "AIRLINENAME": "CommutAir"
    },
    {
        "AIRLINECODE": "C7",
        "AIRLINENAME": "Aerocontinente Chile"
    },
    {
        "AIRLINECODE": "C8",
        "AIRLINENAME": "Chicago Express Airlines"
    },
    {
        "AIRLINECODE": "C9",
        "AIRLINENAME": "Cirrus Airlines"
    },
    {
        "AIRLINECODE": "CA",
        "AIRLINENAME": "Air China"
    },
    {
        "AIRLINECODE": "CB",
        "AIRLINENAME": "ScotAirways"
    },
    {
        "AIRLINECODE": "CC",
        "AIRLINENAME": "Macair"
    },
    {
        "AIRLINECODE": "CD",
        "AIRLINENAME": "Alliance-Air"
    },
    {
        "AIRLINECODE": "CE",
        "AIRLINENAME": "Nationwide Air"
    },
    {
        "AIRLINECODE": "CF",
        "AIRLINENAME": "City Airline"
    },
    {
        "AIRLINECODE": "CG",
        "AIRLINENAME": "Milne Bay Air"
    },
    {
        "AIRLINECODE": "CH",
        "AIRLINENAME": "Bemidji Aviation"
    },
    {
        "AIRLINECODE": "CI",
        "AIRLINENAME": "China Airline"
    },
    {
        "AIRLINECODE": "CJ",
        "AIRLINENAME": "China Northern Airlines"
    },
    {
        "AIRLINECODE": "CK",
        "AIRLINENAME": "Andesmar Lineas Aereas S"
    },
    {
        "AIRLINECODE": "CL",
        "AIRLINENAME": "Lufthansa Cityline Gmbh"
    },
    {
        "AIRLINECODE": "CM",
        "AIRLINENAME": "Copa Airlines"
    },
    {
        "AIRLINECODE": "CN",
        "AIRLINENAME": "Islands Nationair"
    },
    {
        "AIRLINECODE": "CO",
        "AIRLINENAME": "COBALTAIR"
    },
    {
        "AIRLINECODE": "CP",
        "AIRLINENAME": "Canadian Airlines International"
    },
    {
        "AIRLINECODE": "CQ",
        "AIRLINENAME": "Czec Connect Airlines"
    },
    {
        "AIRLINECODE": "CS",
        "AIRLINENAME": "Continental Micronesia"
    },
    {
        "AIRLINECODE": "CT",
        "AIRLINENAME": "ALITALIA CITY LINER SPA"
    },
    {
        "AIRLINECODE": "CU",
        "AIRLINENAME": "Consolidada Cubana De Avia"
    },
    {
        "AIRLINECODE": "CV",
        "AIRLINENAME": "Air Chathams"
    },
    {
        "AIRLINECODE": "CW",
        "AIRLINENAME": "Airline Of Marshall Islands"
    },
    {
        "AIRLINECODE": "CX",
        "AIRLINENAME": "Cathay Pacific Airways"
    },
    {
        "AIRLINECODE": "CY",
        "AIRLINENAME": "Cyprus Airways"
    },
    {
        "AIRLINECODE": "CZ",
        "AIRLINENAME": "Chinasouthern"
    },
    {
        "AIRLINECODE": "D2",
        "AIRLINENAME": "Severstal Aircompany"
    },
    {
        "AIRLINECODE": "D3",
        "AIRLINENAME": "Daallo Airlines"
    },
    {
        "AIRLINECODE": "D4",
        "AIRLINENAME": "Alidaunai"
    },
    {
        "AIRLINECODE": "D6",
        "AIRLINENAME": "Inter Aviatn"
    },
    {
        "AIRLINECODE": "D7",
        "AIRLINENAME": "AirAsia X"
    },
    {
        "AIRLINECODE": "D8",
        "AIRLINENAME": "Djibouti Airlines"
    },
    {
        "AIRLINECODE": "D9",
        "AIRLINENAME": "Aeroflot-Don"
    },
    {
        "AIRLINECODE": "DB",
        "AIRLINENAME": "Brittany Air International"
    },
    {
        "AIRLINECODE": "DC",
        "AIRLINENAME": "BRAATHENS REGIONAL"
    },
    {
        "AIRLINECODE": "DD",
        "AIRLINENAME": "Atlantic Coast Jet"
    },
    {
        "AIRLINECODE": "DE",
        "AIRLINENAME": "Condor Flugdienst Gmbh"
    },
    {
        "AIRLINECODE": "DF",
        "AIRLINENAME": "Aebal"
    },
    {
        "AIRLINECODE": "DG",
        "AIRLINENAME": "Tigerair Philippines"
    },
    {
        "AIRLINECODE": "DH",
        "AIRLINENAME": "Independence"
    },
    {
        "AIRLINECODE": "DI",
        "AIRLINENAME": "Deutsche BA"
    },
    {
        "AIRLINECODE": "DJ",
        "AIRLINENAME": "Virgin Blue"
    },
    {
        "AIRLINECODE": "DK",
        "AIRLINENAME": "Eastland Air"
    },
    {
        "AIRLINECODE": "DL",
        "AIRLINENAME": "Delta Air Lines"
    },
    {
        "AIRLINECODE": "DM",
        "AIRLINENAME": "Maersk Air"
    },
    {
        "AIRLINECODE": "DN",
        "AIRLINENAME": "Senegal Airlines"
    },
    {
        "AIRLINECODE": "DO",
        "AIRLINENAME": "Air Vallee"
    },
    {
        "AIRLINECODE": "DP",
        "AIRLINENAME": "Air 2000 Limited"
    },
    {
        "AIRLINECODE": "DQ",
        "AIRLINENAME": "Coastal Air Transport"
    },
    {
        "AIRLINECODE": "DR",
        "AIRLINENAME": "Airmediterranee"
    },
    {
        "AIRLINECODE": "DT",
        "AIRLINENAME": "Taag Linhas Aereas De Angola"
    },
    {
        "AIRLINECODE": "DU",
        "AIRLINENAME": "Hemus Air"
    },
    {
        "AIRLINECODE": "DV",
        "AIRLINENAME": "JSC Aircompany Scat"
    },
    {
        "AIRLINECODE": "DX",
        "AIRLINENAME": "Danish Air Transport"
    },
    {
        "AIRLINECODE": "DY",
        "AIRLINENAME": "NORWEGIAN AIR SHUTTLE"
    },
    {
        "AIRLINECODE": "DZ",
        "AIRLINENAME": "Transcaraibes"
    },
    {
        "AIRLINECODE": "E2",
        "AIRLINENAME": "Kampuchea Airlines"
    },
    {
        "AIRLINECODE": "E3",
        "AIRLINENAME": "Domodedovo Airlines"
    },
    {
        "AIRLINECODE": "E4",
        "AIRLINENAME": "Aero Asia International"
    },
    {
        "AIRLINECODE": "E5",
        "AIRLINENAME": "Samara Airlines"
    },
    {
        "AIRLINECODE": "E6",
        "AIRLINENAME": "Aviaexpresscruise Airline"
    },
    {
        "AIRLINECODE": "E7",
        "AIRLINENAME": "Equaflight Services"
    },
    {
        "AIRLINECODE": "E8",
        "AIRLINENAME": "Alpi Eagles"
    },
    {
        "AIRLINECODE": "E9",
        "AIRLINENAME": "EVELOP AIRLINES"
    },
    {
        "AIRLINECODE": "EA",
        "AIRLINENAME": "European Regions Airlines"
    },
    {
        "AIRLINECODE": "EC",
        "AIRLINENAME": "Heli Inter Riviera"
    },
    {
        "AIRLINECODE": "ED",
        "AIRLINENAME": "Ccair"
    },
    {
        "AIRLINECODE": "EE",
        "AIRLINENAME": "REGIONAL JET"
    },
    {
        "AIRLINECODE": "EF",
        "AIRLINENAME": "Far Eastern Air"
    },
    {
        "AIRLINECODE": "EG",
        "AIRLINENAME": "Japan Asia Airways"
    },
    {
        "AIRLINECODE": "EH",
        "AIRLINENAME": "ANA WINGS"
    },
    {
        "AIRLINECODE": "EI",
        "AIRLINENAME": "Aer Lingus Teoranta Aerlinte Eirean"
    },
    {
        "AIRLINECODE": "EJ",
        "AIRLINENAME": "New England Airlines"
    },
    {
        "AIRLINECODE": "EK",
        "AIRLINENAME": "Emirates Airlines"
    },
    {
        "AIRLINECODE": "EL",
        "AIRLINENAME": "Ellinair"
    },
    {
        "AIRLINECODE": "EM",
        "AIRLINENAME": "Western Airlines"
    },
    {
        "AIRLINECODE": "EN",
        "AIRLINENAME": "Air Dolomiti"
    },
    {
        "AIRLINECODE": "EO",
        "AIRLINENAME": "Hewa Bora Airways"
    },
    {
        "AIRLINECODE": "EQ",
        "AIRLINENAME": "Transportes Aereos Militates ecuador"
    },
    {
        "AIRLINECODE": "ET",
        "AIRLINENAME": "Ethiopian Airlines S C"
    },
    {
        "AIRLINECODE": "EU",
        "AIRLINENAME": "Empresa Ecuatoriana De Aviacion Sa"
    },
    {
        "AIRLINECODE": "EV",
        "AIRLINENAME": "Atlantic Southeast Airlines"
    },
    {
        "AIRLINECODE": "EW",
        "AIRLINENAME": "Eurowings"
    },
    {
        "AIRLINECODE": "EX",
        "AIRLINENAME": "Air Santo Domingo"
    },
    {
        "AIRLINECODE": "EY",
        "AIRLINENAME": "Etihad Airways"
    },
    {
        "AIRLINECODE": "EZ",
        "AIRLINENAME": "Sun-Air"
    },
    {
        "AIRLINECODE": "F2",
        "AIRLINENAME": "Safarilink"
    },
    {
        "AIRLINECODE": "F3",
        "AIRLINENAME": "Flying Enterprise Ab"
    },
    {
        "AIRLINECODE": "F4",
        "AIRLINENAME": "Eureca"
    },
    {
        "AIRLINECODE": "F5",
        "AIRLINENAME": "Cosmic Air"
    },
    {
        "AIRLINECODE": "F6",
        "AIRLINENAME": "China National Aviation"
    },
    {
        "AIRLINECODE": "F7",
        "AIRLINENAME": "Etihad Regional"
    },
    {
        "AIRLINECODE": "F8",
        "AIRLINENAME": "FLAIR AIRLINES"
    },
    {
        "AIRLINECODE": "F9",
        "AIRLINENAME": "Frontier Airlines"
    },
    {
        "AIRLINECODE": "FA",
        "AIRLINENAME": "SAF AIR"
    },
    {
        "AIRLINECODE": "FB",
        "AIRLINENAME": "Biulgaria Air"
    },
    {
        "AIRLINECODE": "FC",
        "AIRLINENAME": "Templehof Express Airlines"
    },
    {
        "AIRLINECODE": "FD",
        "AIRLINENAME": "Cityflyer Exp"
    },
    {
        "AIRLINECODE": "FE",
        "AIRLINENAME": "Royal Khmer Airlines"
    },
    {
        "AIRLINECODE": "FG",
        "AIRLINENAME": "Bakhtar Afghan Airlines"
    },
    {
        "AIRLINECODE": "FI",
        "AIRLINENAME": "Icelandair"
    },
    {
        "AIRLINECODE": "FJ",
        "AIRLINENAME": "Fiji Airways"
    },
    {
        "AIRLINECODE": "FK",
        "AIRLINENAME": "Keewatin Air"
    },
    {
        "AIRLINECODE": "FL",
        "AIRLINENAME": "Airtran Airways"
    },
    {
        "AIRLINECODE": "FM",
        "AIRLINENAME": "Shanghai Airlines"
    },
    {
        "AIRLINECODE": "FN",
        "AIRLINENAME": "fastjet"
    },
    {
        "AIRLINECODE": "FQ",
        "AIRLINENAME": "Thomas Cook Belgium Airlines"
    },
    {
        "AIRLINECODE": "FR",
        "AIRLINENAME": "Ryan Air"
    },
    {
        "AIRLINECODE": "FS",
        "AIRLINENAME": "Syphax Airlines"
    },
    {
        "AIRLINECODE": "FU",
        "AIRLINENAME": "Air Littoral"
    },
    {
        "AIRLINECODE": "FV",
        "AIRLINENAME": "ROSSIYA-RUSSIAN AIRLINES"
    },
    {
        "AIRLINECODE": "FW",
        "AIRLINENAME": "Fair"
    },
    {
        "AIRLINECODE": "FY",
        "AIRLINENAME": "Firefly"
    },
    {
        "AIRLINECODE": "FZ",
        "AIRLINENAME": "Fly Dubai"
    },
    {
        "AIRLINECODE": "G2",
        "AIRLINENAME": "Avirex Gabon"
    },
    {
        "AIRLINECODE": "G3",
        "AIRLINENAME": "GOL Linhas Aereas Inteligentes"
    },
    {
        "AIRLINECODE": "G4",
        "AIRLINENAME": "Allegiant Air"
    },
    {
        "AIRLINECODE": "G5",
        "AIRLINENAME": "Enkor Airlines"
    },
    {
        "AIRLINECODE": "G6",
        "AIRLINENAME": "Guine Bissau Airlines"
    },
    {
        "AIRLINECODE": "G7",
        "AIRLINENAME": "Gandalf Airlines"
    },
    {
        "AIRLINECODE": "G8",
        "AIRLINENAME": "GoAir"
    },
    {
        "AIRLINECODE": "G9",
        "AIRLINENAME": "Air Arabia"
    },
    {
        "AIRLINECODE": "GA",
        "AIRLINENAME": "Garuda"
    },
    {
        "AIRLINECODE": "GC",
        "AIRLINENAME": "Gambia International Airlines"
    },
    {
        "AIRLINECODE": "GE",
        "AIRLINENAME": "Transasia Airways"
    },
    {
        "AIRLINECODE": "GF",
        "AIRLINENAME": "Gulf Air"
    },
    {
        "AIRLINECODE": "GG",
        "AIRLINENAME": "Air Guyane"
    },
    {
        "AIRLINECODE": "GH",
        "AIRLINENAME": "Ghana Airways Corporation"
    },
    {
        "AIRLINECODE": "GJ",
        "AIRLINENAME": "Eurofly"
    },
    {
        "AIRLINECODE": "GK",
        "AIRLINENAME": "Go One Airways"
    },
    {
        "AIRLINECODE": "GL",
        "AIRLINENAME": "Global International Airways Corp"
    },
    {
        "AIRLINECODE": "GM",
        "AIRLINENAME": "Air Slovakia Bwj"
    },
    {
        "AIRLINECODE": "GN",
        "AIRLINENAME": "Air Gabon"
    },
    {
        "AIRLINECODE": "GO",
        "AIRLINENAME": "Go"
    },
    {
        "AIRLINECODE": "GP",
        "AIRLINENAME": "APG Airline"
    },
    {
        "AIRLINECODE": "GQ",
        "AIRLINENAME": "Big Sky Airlines"
    },
    {
        "AIRLINECODE": "GR",
        "AIRLINENAME": "Aurigny Air Services"
    },
    {
        "AIRLINECODE": "GS",
        "AIRLINENAME": "Grant"
    },
    {
        "AIRLINECODE": "GT",
        "AIRLINENAME": "Gibraltar Airways"
    },
    {
        "AIRLINECODE": "GU",
        "AIRLINENAME": "Aviateca S A"
    },
    {
        "AIRLINECODE": "GW",
        "AIRLINENAME": "Air Lines Of Kuban"
    },
    {
        "AIRLINECODE": "GX",
        "AIRLINENAME": "Pacificair"
    },
    {
        "AIRLINECODE": "GY",
        "AIRLINENAME": "Guyana Airways Corporation"
    },
    {
        "AIRLINECODE": "GZ",
        "AIRLINENAME": "Air Rarotonga"
    },
    {
        "AIRLINECODE": "H1",
        "AIRLINENAME": "Hahn Air Systems"
    },
    {
        "AIRLINECODE": "H2",
        "AIRLINENAME": "Sky Airline"
    },
    {
        "AIRLINECODE": "H3",
        "AIRLINENAME": "Harbour Air"
    },
    {
        "AIRLINECODE": "H4",
        "AIRLINENAME": "Inter Islands Airlines"
    },
    {
        "AIRLINECODE": "H5",
        "AIRLINENAME": "Magadan Air"
    },
    {
        "AIRLINECODE": "H6",
        "AIRLINENAME": "Hageland"
    },
    {
        "AIRLINECODE": "H7",
        "AIRLINENAME": "Eagle Aviation Uganda"
    },
    {
        "AIRLINECODE": "H8",
        "AIRLINENAME": "Dalavia-Far East Airways Khabarovsk"
    },
    {
        "AIRLINECODE": "H9",
        "AIRLINENAME": "Himalyan Airline"
    },
    {
        "AIRLINECODE": "HA",
        "AIRLINENAME": "Hawaiian Airlines"
    },
    {
        "AIRLINECODE": "HB",
        "AIRLINENAME": "Homer Air"
    },
    {
        "AIRLINECODE": "HC",
        "AIRLINENAME": "Aero-Tropics Air Services"
    },
    {
        "AIRLINECODE": "HD",
        "AIRLINENAME": "Hokkaido International Airlines"
    },
    {
        "AIRLINECODE": "HE",
        "AIRLINENAME": "Lgw Luftfahrtgesellschaft"
    },
    {
        "AIRLINECODE": "HF",
        "AIRLINENAME": "Air Côte d'Ivoire"
    },
    {
        "AIRLINECODE": "HG",
        "AIRLINENAME": "Niki"
    },
    {
        "AIRLINECODE": "HH",
        "AIRLINENAME": "Islandsflug"
    },
    {
        "AIRLINECODE": "HI",
        "AIRLINENAME": "Papillon Air"
    },
    {
        "AIRLINECODE": "HJ",
        "AIRLINENAME": "Swedeways Air Lines"
    },
    {
        "AIRLINECODE": "HK",
        "AIRLINENAME": "Yangon Airways"
    },
    {
        "AIRLINECODE": "HM",
        "AIRLINENAME": "Air Mahe\/Inter Island Airways"
    },
    {
        "AIRLINECODE": "HN",
        "AIRLINENAME": "Proteus Helicopteres"
    },
    {
        "AIRLINECODE": "HO",
        "AIRLINENAME": "Juneyao Airline"
    },
    {
        "AIRLINECODE": "HP",
        "AIRLINENAME": "America West"
    },
    {
        "AIRLINECODE": "HQ",
        "AIRLINENAME": "THOMAS COOK AIRLINES BELGIUM"
    },
    {
        "AIRLINECODE": "HR",
        "AIRLINENAME": "Hahn Air"
    },
    {
        "AIRLINECODE": "HS",
        "AIRLINENAME": "Skyways Regional"
    },
    {
        "AIRLINECODE": "HT",
        "AIRLINENAME": "Royal Airways"
    },
    {
        "AIRLINECODE": "HU",
        "AIRLINENAME": "Hainan Airlines"
    },
    {
        "AIRLINECODE": "HV",
        "AIRLINENAME": "Transavia Airlines"
    },
    {
        "AIRLINECODE": "HW",
        "AIRLINENAME": "North Wright"
    },
    {
        "AIRLINECODE": "HX",
        "AIRLINENAME": "Hong Kong Airlines"
    },
    {
        "AIRLINECODE": "HY",
        "AIRLINENAME": "Uzbekistan Airways"
    },
    {
        "AIRLINECODE": "HZ",
        "AIRLINENAME": "Aurora Airlines"
    },
    {
        "AIRLINECODE": "I2",
        "AIRLINENAME": "IBERIA EXPRESS"
    },
    {
        "AIRLINECODE": "I5",
        "AIRLINENAME": "Air Asia"
    },
    {
        "AIRLINECODE": "I7",
        "AIRLINENAME": "Paramount Airways"
    },
    {
        "AIRLINECODE": "I9",
        "AIRLINENAME": "Air-Service"
    },
    {
        "AIRLINECODE": "IA",
        "AIRLINENAME": "Iraqi\/Airways"
    },
    {
        "AIRLINECODE": "IB",
        "AIRLINENAME": "Iberia Airlines"
    },
    {
        "AIRLINECODE": "IC",
        "AIRLINENAME": "Indian Airlines"
    },
    {
        "AIRLINECODE": "ID",
        "AIRLINENAME": "BATIK AIR INDONESIA"
    },
    {
        "AIRLINECODE": "IE",
        "AIRLINENAME": "Solomon Islands Airways"
    },
    {
        "AIRLINECODE": "IG",
        "AIRLINENAME": "AIR ITALY"
    },
    {
        "AIRLINECODE": "IH",
        "AIRLINENAME": "Falcon Air"
    },
    {
        "AIRLINECODE": "IJ",
        "AIRLINENAME": "Air Liberte"
    },
    {
        "AIRLINECODE": "IK",
        "AIRLINENAME": "Imair"
    },
    {
        "AIRLINECODE": "IM",
        "AIRLINENAME": "Intensive Air"
    },
    {
        "AIRLINECODE": "IN",
        "AIRLINENAME": "Mat Macedonia"
    },
    {
        "AIRLINECODE": "IP",
        "AIRLINENAME": "Atyrau Airways"
    },
    {
        "AIRLINECODE": "IQ",
        "AIRLINENAME": "Augsburg Airways"
    },
    {
        "AIRLINECODE": "IR",
        "AIRLINENAME": "Iran National Airlines"
    },
    {
        "AIRLINECODE": "IS",
        "AIRLINENAME": "Island Airlines"
    },
    {
        "AIRLINECODE": "IT",
        "AIRLINENAME": "Tigerair Taiwan"
    },
    {
        "AIRLINECODE": "IW",
        "AIRLINENAME": "Air Lib"
    },
    {
        "AIRLINECODE": "IX",
        "AIRLINENAME": "Air India Express"
    },
    {
        "AIRLINECODE": "IY",
        "AIRLINENAME": "Yemen Airways Corporation"
    },
    {
        "AIRLINECODE": "IZ",
        "AIRLINENAME": "Arkia Israel Inland Airlines"
    },
    {
        "AIRLINECODE": "J0",
        "AIRLINENAME": "JETLINK EXPRE"
    },
    {
        "AIRLINECODE": "J2",
        "AIRLINENAME": "Azerbaijan Airlines"
    },
    {
        "AIRLINECODE": "J3",
        "AIRLINENAME": "Northwestern Air Lease"
    },
    {
        "AIRLINECODE": "J4",
        "AIRLINENAME": "Buffalo Air"
    },
    {
        "AIRLINECODE": "J5",
        "AIRLINENAME": "Alaska Seaplanes"
    },
    {
        "AIRLINECODE": "J6",
        "AIRLINENAME": "Larrys Flying"
    },
    {
        "AIRLINECODE": "J7",
        "AIRLINENAME": "Centre-Avia Airlines"
    },
    {
        "AIRLINECODE": "J8",
        "AIRLINENAME": "Berjaya Air"
    },
    {
        "AIRLINECODE": "J9",
        "AIRLINENAME": "Jazera Airways"
    },
    {
        "AIRLINECODE": "JA",
        "AIRLINENAME": "BH Airlines"
    },
    {
        "AIRLINECODE": "JB",
        "AIRLINENAME": "Helijet International"
    },
    {
        "AIRLINECODE": "JC",
        "AIRLINENAME": "JAL Express"
    },
    {
        "AIRLINECODE": "JD",
        "AIRLINENAME": "Japan Air Sys"
    },
    {
        "AIRLINECODE": "JE",
        "AIRLINENAME": "Mango"
    },
    {
        "AIRLINECODE": "JF",
        "AIRLINENAME": "Jet Asia Airways"
    },
    {
        "AIRLINECODE": "JH",
        "AIRLINENAME": "Nordeste-Linhas Aereas Regionais Sa"
    },
    {
        "AIRLINECODE": "JI",
        "AIRLINENAME": "Midway Airlines"
    },
    {
        "AIRLINECODE": "JJ",
        "AIRLINENAME": "TAM Linhas Aereas"
    },
    {
        "AIRLINECODE": "JK",
        "AIRLINENAME": "Spanair"
    },
    {
        "AIRLINECODE": "JL",
        "AIRLINENAME": "Japan Airlines"
    },
    {
        "AIRLINECODE": "JM",
        "AIRLINENAME": "Air Jamaica"
    },
    {
        "AIRLINECODE": "JN",
        "AIRLINENAME": "Excel Airways"
    },
    {
        "AIRLINECODE": "JO",
        "AIRLINENAME": "JALways"
    },
    {
        "AIRLINECODE": "JP",
        "AIRLINENAME": "Adria Airways"
    },
    {
        "AIRLINECODE": "JQ",
        "AIRLINENAME": "Jet"
    },
    {
        "AIRLINECODE": "JR",
        "AIRLINENAME": "Aerocalfornia"
    },
    {
        "AIRLINECODE": "JS",
        "AIRLINENAME": "Air Koryo"
    },
    {
        "AIRLINECODE": "JT",
        "AIRLINENAME": "LionAir"
    },
    {
        "AIRLINECODE": "JU",
        "AIRLINENAME": "Air Serbia"
    },
    {
        "AIRLINECODE": "JV",
        "AIRLINENAME": "Bearskin Lake"
    },
    {
        "AIRLINECODE": "JW",
        "AIRLINENAME": "Skippers Aviation"
    },
    {
        "AIRLINECODE": "JX",
        "AIRLINENAME": "Nice Helicopteres"
    },
    {
        "AIRLINECODE": "JY",
        "AIRLINENAME": "INTERCARIBBEAN AIRWAYS"
    },
    {
        "AIRLINECODE": "JZ",
        "AIRLINENAME": "Skyways Ab"
    },
    {
        "AIRLINECODE": "K2",
        "AIRLINENAME": "Eurolot S.A"
    },
    {
        "AIRLINECODE": "K3",
        "AIRLINENAME": "Taquan Air Services"
    },
    {
        "AIRLINECODE": "K4",
        "AIRLINENAME": "Kronflyg"
    },
    {
        "AIRLINECODE": "K5",
        "AIRLINENAME": "Seaport Airlines"
    },
    {
        "AIRLINECODE": "K6",
        "AIRLINENAME": "Cambodia Angkor Air"
    },
    {
        "AIRLINECODE": "K7",
        "AIRLINENAME": "Sakha Avia"
    },
    {
        "AIRLINECODE": "K9",
        "AIRLINENAME": "Skyward Aviation"
    },
    {
        "AIRLINECODE": "KA",
        "AIRLINENAME": "Dragonair"
    },
    {
        "AIRLINECODE": "KB",
        "AIRLINENAME": "Druk Air"
    },
    {
        "AIRLINECODE": "KC",
        "AIRLINENAME": "Air Astana"
    },
    {
        "AIRLINECODE": "KD",
        "AIRLINENAME": "KD Avia"
    },
    {
        "AIRLINECODE": "KE",
        "AIRLINENAME": "Korean Air Lines"
    },
    {
        "AIRLINECODE": "KF",
        "AIRLINENAME": "Oy Air Botnia"
    },
    {
        "AIRLINECODE": "KH",
        "AIRLINENAME": "Siem Reap Airways International"
    },
    {
        "AIRLINECODE": "KI",
        "AIRLINENAME": "Time Air"
    },
    {
        "AIRLINECODE": "KJ",
        "AIRLINENAME": "British Mediterranean"
    },
    {
        "AIRLINECODE": "KK",
        "AIRLINENAME": "Atlasjet Airlines"
    },
    {
        "AIRLINECODE": "KL",
        "AIRLINENAME": "Klm Royal Dutch Airlines"
    },
    {
        "AIRLINECODE": "KM",
        "AIRLINENAME": "Air Malta"
    },
    {
        "AIRLINECODE": "KN",
        "AIRLINENAME": "CHINA UNITED AIRLINES"
    },
    {
        "AIRLINECODE": "KO",
        "AIRLINENAME": "Alaska Central Express"
    },
    {
        "AIRLINECODE": "KP",
        "AIRLINENAME": "ASKY"
    },
    {
        "AIRLINECODE": "KQ",
        "AIRLINENAME": "Kenya Airways"
    },
    {
        "AIRLINECODE": "KR",
        "AIRLINENAME": "Air Bishkek"
    },
    {
        "AIRLINECODE": "KS",
        "AIRLINENAME": "Penair"
    },
    {
        "AIRLINECODE": "KU",
        "AIRLINENAME": "Kuwait Airways Corporation"
    },
    {
        "AIRLINECODE": "KV",
        "AIRLINENAME": "Kavminvodyavi"
    },
    {
        "AIRLINECODE": "KW",
        "AIRLINENAME": "Air Company KAS"
    },
    {
        "AIRLINECODE": "KX",
        "AIRLINENAME": "Cayman Airways"
    },
    {
        "AIRLINECODE": "KY",
        "AIRLINENAME": "Air Sao Tome e Principe"
    },
    {
        "AIRLINECODE": "L2",
        "AIRLINENAME": "Lynden Air"
    },
    {
        "AIRLINECODE": "L4",
        "AIRLINENAME": "Lauda-air S.p.A."
    },
    {
        "AIRLINECODE": "L5",
        "AIRLINENAME": "Lufttransport AS"
    },
    {
        "AIRLINECODE": "L6",
        "AIRLINENAME": "Air Maldives"
    },
    {
        "AIRLINECODE": "L7",
        "AIRLINENAME": "Laoag International Airlines"
    },
    {
        "AIRLINECODE": "L8",
        "AIRLINENAME": "AFRIC AVIATION"
    },
    {
        "AIRLINECODE": "L9",
        "AIRLINENAME": "Air Mali S.A."
    },
    {
        "AIRLINECODE": "LA",
        "AIRLINENAME": "Lan-Linea Aerea Nacional-Chile Sa"
    },
    {
        "AIRLINECODE": "LB",
        "AIRLINENAME": "Air Costa"
    },
    {
        "AIRLINECODE": "LC",
        "AIRLINENAME": "ECAIR Equatorial Congo Airlines"
    },
    {
        "AIRLINECODE": "LD",
        "AIRLINENAME": "Linea Turistica Aereotuy"
    },
    {
        "AIRLINECODE": "LE",
        "AIRLINENAME": "Helgoland Airlines"
    },
    {
        "AIRLINECODE": "LF",
        "AIRLINENAME": "Nordic East"
    },
    {
        "AIRLINECODE": "LG",
        "AIRLINENAME": "Luxair"
    },
    {
        "AIRLINECODE": "LH",
        "AIRLINENAME": "Lufthansa"
    },
    {
        "AIRLINECODE": "LI",
        "AIRLINENAME": "Liat"
    },
    {
        "AIRLINECODE": "LJ",
        "AIRLINENAME": "Sierra Nation"
    },
    {
        "AIRLINECODE": "LK",
        "AIRLINENAME": "Continent Airlines"
    },
    {
        "AIRLINECODE": "LL",
        "AIRLINENAME": "Allegro"
    },
    {
        "AIRLINECODE": "LM",
        "AIRLINENAME": "Air ALM"
    },
    {
        "AIRLINECODE": "LN",
        "AIRLINENAME": "Libyan Arab Airlines"
    },
    {
        "AIRLINECODE": "LO",
        "AIRLINENAME": "Lot-Polish"
    },
    {
        "AIRLINECODE": "LP",
        "AIRLINENAME": "LanPeru"
    },
    {
        "AIRLINECODE": "LQ",
        "AIRLINENAME": "LANMEI AIRLINES"
    },
    {
        "AIRLINECODE": "LR",
        "AIRLINENAME": "Lineas Aereas Coricenses"
    },
    {
        "AIRLINECODE": "LS",
        "AIRLINENAME": "Jet2"
    },
    {
        "AIRLINECODE": "LT",
        "AIRLINENAME": "Air Lituanica"
    },
    {
        "AIRLINECODE": "LV",
        "AIRLINENAME": "MEGA Maldives Airlines"
    },
    {
        "AIRLINECODE": "LW",
        "AIRLINENAME": "Pacific Wings"
    },
    {
        "AIRLINECODE": "LX",
        "AIRLINENAME": "Swiss"
    },
    {
        "AIRLINECODE": "LY",
        "AIRLINENAME": "El Al Israel Airlines Limited"
    },
    {
        "AIRLINECODE": "LZ",
        "AIRLINENAME": "Belle Air"
    },
    {
        "AIRLINECODE": "M3",
        "AIRLINENAME": "Air Service"
    },
    {
        "AIRLINECODE": "M4",
        "AIRLINENAME": "Avioimpex A.D.P.O."
    },
    {
        "AIRLINECODE": "M5",
        "AIRLINENAME": "Kenmore"
    },
    {
        "AIRLINECODE": "M7",
        "AIRLINENAME": "Marsland Aviation"
    },
    {
        "AIRLINECODE": "M9",
        "AIRLINENAME": "Motor Sich PISC"
    },
    {
        "AIRLINECODE": "MA",
        "AIRLINENAME": "Malev Hungarian Airlines"
    },
    {
        "AIRLINECODE": "MC",
        "AIRLINENAME": "Military Air"
    },
    {
        "AIRLINECODE": "MD",
        "AIRLINENAME": "Air Madagascar"
    },
    {
        "AIRLINECODE": "ME",
        "AIRLINENAME": "Middle East Airlines Airliban"
    },
    {
        "AIRLINECODE": "MF",
        "AIRLINENAME": "Xiamen Airlines"
    },
    {
        "AIRLINECODE": "MG",
        "AIRLINENAME": "Champion Air"
    },
    {
        "AIRLINECODE": "MH",
        "AIRLINENAME": "Malaysia Airlines"
    },
    {
        "AIRLINECODE": "MI",
        "AIRLINENAME": "Silkair"
    },
    {
        "AIRLINECODE": "MJ",
        "AIRLINENAME": "Mihin Lanka"
    },
    {
        "AIRLINECODE": "MK",
        "AIRLINENAME": "Air Mauritius"
    },
    {
        "AIRLINECODE": "ML",
        "AIRLINENAME": "AIR MEDITERRANEE"
    },
    {
        "AIRLINECODE": "MM",
        "AIRLINENAME": "Medellin"
    },
    {
        "AIRLINECODE": "MN",
        "AIRLINENAME": "kulula"
    },
    {
        "AIRLINECODE": "MO",
        "AIRLINENAME": "Calm Air Intl"
    },
    {
        "AIRLINECODE": "MP",
        "AIRLINENAME": "Martinair"
    },
    {
        "AIRLINECODE": "MQ",
        "AIRLINENAME": "American Eagle"
    },
    {
        "AIRLINECODE": "MR",
        "AIRLINENAME": "Hunnu Air"
    },
    {
        "AIRLINECODE": "MS",
        "AIRLINENAME": "Egyptair"
    },
    {
        "AIRLINECODE": "MT",
        "AIRLINENAME": "Fly Thomascook"
    },
    {
        "AIRLINECODE": "MU",
        "AIRLINENAME": "China Eastern"
    },
    {
        "AIRLINECODE": "MV",
        "AIRLINENAME": "Manus Air"
    },
    {
        "AIRLINECODE": "MW",
        "AIRLINENAME": "Mokulele Airlines"
    },
    {
        "AIRLINECODE": "MX",
        "AIRLINENAME": "Mexicana"
    },
    {
        "AIRLINECODE": "MY",
        "AIRLINENAME": "Maya Island Air"
    },
    {
        "AIRLINECODE": "MZ",
        "AIRLINENAME": "Merpati Nusantara Dba Air Mali"
    },
    {
        "AIRLINECODE": "N2",
        "AIRLINENAME": "Aerolineas Internacionales"
    },
    {
        "AIRLINECODE": "N6",
        "AIRLINENAME": "Aero Continente"
    },
    {
        "AIRLINECODE": "N7",
        "AIRLINENAME": "National Airlines"
    },
    {
        "AIRLINECODE": "N9",
        "AIRLINENAME": "North Coast Aviation Pty"
    },
    {
        "AIRLINECODE": "NA",
        "AIRLINENAME": "Nesma Airlines Company"
    },
    {
        "AIRLINECODE": "NB",
        "AIRLINENAME": "Sterling"
    },
    {
        "AIRLINECODE": "NC",
        "AIRLINENAME": "National Jet Systems Pty"
    },
    {
        "AIRLINECODE": "ND",
        "AIRLINENAME": "Airlink Limited"
    },
    {
        "AIRLINECODE": "NE",
        "AIRLINENAME": "Nesma Airlines"
    },
    {
        "AIRLINECODE": "NF",
        "AIRLINENAME": "Air Vanuatu"
    },
    {
        "AIRLINECODE": "NG",
        "AIRLINENAME": "Lauda Air"
    },
    {
        "AIRLINECODE": "NH",
        "AIRLINENAME": "All Nippon Airways Co"
    },
    {
        "AIRLINECODE": "NI",
        "AIRLINENAME": "Portugalia"
    },
    {
        "AIRLINECODE": "NJ",
        "AIRLINENAME": "Vanguard Airlines"
    },
    {
        "AIRLINECODE": "NK",
        "AIRLINENAME": "Spirit Airlines"
    },
    {
        "AIRLINECODE": "NL",
        "AIRLINENAME": "Shaheen Air"
    },
    {
        "AIRLINECODE": "NN",
        "AIRLINENAME": "Vim Airlines"
    },
    {
        "AIRLINECODE": "NP",
        "AIRLINENAME": "NILE AIR"
    },
    {
        "AIRLINECODE": "NQ",
        "AIRLINENAME": "Air Japan"
    },
    {
        "AIRLINECODE": "NS",
        "AIRLINENAME": "HEBEI AIRLINES"
    },
    {
        "AIRLINECODE": "NT",
        "AIRLINENAME": "Binter Canarias"
    },
    {
        "AIRLINECODE": "NU",
        "AIRLINENAME": "Japan Transocean Air"
    },
    {
        "AIRLINECODE": "NV",
        "AIRLINENAME": "Northwest Territorial Airways"
    },
    {
        "AIRLINECODE": "NW",
        "AIRLINENAME": "Northwest Airlines"
    },
    {
        "AIRLINECODE": "NX",
        "AIRLINENAME": "Air Macau Company Limited"
    },
    {
        "AIRLINECODE": "NY",
        "AIRLINENAME": "Air Iceland"
    },
    {
        "AIRLINECODE": "NZ",
        "AIRLINENAME": "New Zealand"
    },
    {
        "AIRLINECODE": "O2",
        "AIRLINENAME": "JET AIR"
    },
    {
        "AIRLINECODE": "O5",
        "AIRLINENAME": "COMORES AVIATION INTL"
    },
    {
        "AIRLINECODE": "O6",
        "AIRLINENAME": "Avianca Brasil"
    },
    {
        "AIRLINECODE": "O8",
        "AIRLINENAME": "SIAM AIR TRANSPORT"
    },
    {
        "AIRLINECODE": "OA",
        "AIRLINENAME": "Olympic Airways"
    },
    {
        "AIRLINECODE": "OB",
        "AIRLINENAME": "Boliviana de Aviación"
    },
    {
        "AIRLINECODE": "OC",
        "AIRLINENAME": "Omni"
    },
    {
        "AIRLINECODE": "OD",
        "AIRLINENAME": "Malindo Air"
    },
    {
        "AIRLINECODE": "OE",
        "AIRLINENAME": "Westair Commuter Airlines"
    },
    {
        "AIRLINECODE": "OH",
        "AIRLINENAME": "Comair"
    },
    {
        "AIRLINECODE": "OI",
        "AIRLINENAME": "Aspiring Air"
    },
    {
        "AIRLINECODE": "OK",
        "AIRLINENAME": "Czech Airline"
    },
    {
        "AIRLINECODE": "OL",
        "AIRLINENAME": "Ostfriesische Lufttransport Gmbh"
    },
    {
        "AIRLINECODE": "OM",
        "AIRLINENAME": "Miat Mongolia"
    },
    {
        "AIRLINECODE": "ON",
        "AIRLINENAME": "Air Nauru"
    },
    {
        "AIRLINECODE": "OO",
        "AIRLINENAME": "Skywest Air"
    },
    {
        "AIRLINECODE": "OP",
        "AIRLINENAME": "Air Pegsus"
    },
    {
        "AIRLINECODE": "OQ",
        "AIRLINENAME": "Sunrise Airlines"
    },
    {
        "AIRLINECODE": "OR",
        "AIRLINENAME": "Arkefly"
    },
    {
        "AIRLINECODE": "OS",
        "AIRLINENAME": "Austrian Airlines"
    },
    {
        "AIRLINECODE": "OT",
        "AIRLINENAME": "Aeropelican Air Services"
    },
    {
        "AIRLINECODE": "OU",
        "AIRLINENAME": "Croatia Airlines"
    },
    {
        "AIRLINECODE": "OV",
        "AIRLINENAME": "Estonian Air"
    },
    {
        "AIRLINECODE": "OW",
        "AIRLINENAME": "Executive Airlines\/American Eagle"
    },
    {
        "AIRLINECODE": "OX",
        "AIRLINENAME": "Orient Thai Airlines"
    },
    {
        "AIRLINECODE": "OY",
        "AIRLINENAME": "OMNI AIR INTL"
    },
    {
        "AIRLINECODE": "OZ",
        "AIRLINENAME": "Asiana Air"
    },
    {
        "AIRLINECODE": "P0",
        "AIRLINENAME": "Proflight Zambia"
    },
    {
        "AIRLINECODE": "P2",
        "AIRLINENAME": "TyumenaviaTrans"
    },
    {
        "AIRLINECODE": "P4",
        "AIRLINENAME": "Aero Lineas Sosa"
    },
    {
        "AIRLINECODE": "P5",
        "AIRLINENAME": "Aerorepublica"
    },
    {
        "AIRLINECODE": "P6",
        "AIRLINENAME": "Pascan Aviation"
    },
    {
        "AIRLINECODE": "P7",
        "AIRLINENAME": "East Line Airlines"
    },
    {
        "AIRLINECODE": "P8",
        "AIRLINENAME": "Pantanal Linhas Aereas Sul"
    },
    {
        "AIRLINECODE": "P9",
        "AIRLINENAME": "Peruvian Airlines"
    },
    {
        "AIRLINECODE": "PA",
        "AIRLINENAME": "AIRBLUE"
    },
    {
        "AIRLINECODE": "PB",
        "AIRLINENAME": "Provincial Airlines"
    },
    {
        "AIRLINECODE": "PC",
        "AIRLINENAME": "PEGASUS AIRLINES"
    },
    {
        "AIRLINECODE": "PD",
        "AIRLINENAME": "Porter Airlines"
    },
    {
        "AIRLINECODE": "PE",
        "AIRLINENAME": "Air Europe"
    },
    {
        "AIRLINECODE": "PF",
        "AIRLINENAME": "Palestinian Airlines"
    },
    {
        "AIRLINECODE": "PG",
        "AIRLINENAME": "Bangkok Airways"
    },
    {
        "AIRLINECODE": "PH",
        "AIRLINENAME": "Polynesian Airlines"
    },
    {
        "AIRLINECODE": "PI",
        "AIRLINENAME": "Sol del Paraquay Lineas Aereas"
    },
    {
        "AIRLINECODE": "PJ",
        "AIRLINENAME": "Air Saint-Pierre"
    },
    {
        "AIRLINECODE": "PK",
        "AIRLINENAME": "Pakistan International Airlines"
    },
    {
        "AIRLINECODE": "PM",
        "AIRLINENAME": "Tropic Air"
    },
    {
        "AIRLINECODE": "PN",
        "AIRLINENAME": "Pan American Airways Corp."
    },
    {
        "AIRLINECODE": "PQ",
        "AIRLINENAME": "AIRASIA PHILIPPINES"
    },
    {
        "AIRLINECODE": "PR",
        "AIRLINENAME": "Philippine Airlines"
    },
    {
        "AIRLINECODE": "PS",
        "AIRLINENAME": "Ukraine International Airlines"
    },
    {
        "AIRLINECODE": "PT",
        "AIRLINENAME": "West Air Sweden"
    },
    {
        "AIRLINECODE": "PU",
        "AIRLINENAME": "Primeras Lineas Uruguayas"
    },
    {
        "AIRLINECODE": "PV",
        "AIRLINENAME": "Saint Barth Commuter"
    },
    {
        "AIRLINECODE": "PW",
        "AIRLINENAME": "Precisionair"
    },
    {
        "AIRLINECODE": "PX",
        "AIRLINENAME": "Air Niugini"
    },
    {
        "AIRLINECODE": "PY",
        "AIRLINENAME": "Surinam Airways"
    },
    {
        "AIRLINECODE": "PZ",
        "AIRLINENAME": "Transportes Aereos Del Mercosur"
    },
    {
        "AIRLINECODE": "Q2",
        "AIRLINENAME": "Island Aviation Services"
    },
    {
        "AIRLINECODE": "Q3",
        "AIRLINENAME": "Zambian Airways"
    },
    {
        "AIRLINECODE": "Q5",
        "AIRLINENAME": "40 Mile Air"
    },
    {
        "AIRLINECODE": "Q7",
        "AIRLINENAME": "Sobelair"
    },
    {
        "AIRLINECODE": "Q8",
        "AIRLINENAME": "Pacific East Asia Cargo Airlines"
    },
    {
        "AIRLINECODE": "Q9",
        "AIRLINENAME": "Air Liaison"
    },
    {
        "AIRLINECODE": "QA",
        "AIRLINENAME": "Aerocaribe"
    },
    {
        "AIRLINECODE": "QB",
        "AIRLINENAME": "SkyGeorgia"
    },
    {
        "AIRLINECODE": "QC",
        "AIRLINENAME": "Camair-Co"
    },
    {
        "AIRLINECODE": "QD",
        "AIRLINENAME": "JC CAMBODIA INTL AIRLINES"
    },
    {
        "AIRLINECODE": "QE",
        "AIRLINENAME": "Europ Continental Airways d\/b\/a Crossair Europe"
    },
    {
        "AIRLINECODE": "QF",
        "AIRLINENAME": "Qantas Airways"
    },
    {
        "AIRLINECODE": "QG",
        "AIRLINENAME": "Delta Air Transport"
    },
    {
        "AIRLINECODE": "QH",
        "AIRLINENAME": "Air Kyrgyzstan"
    },
    {
        "AIRLINECODE": "QI",
        "AIRLINENAME": "Cimber Air"
    },
    {
        "AIRLINECODE": "QJ",
        "AIRLINENAME": "Latpass Airlines"
    },
    {
        "AIRLINECODE": "QK",
        "AIRLINENAME": "Air Nova"
    },
    {
        "AIRLINECODE": "QL",
        "AIRLINENAME": "Aero Lanka"
    },
    {
        "AIRLINECODE": "QM",
        "AIRLINENAME": "Air Malawi"
    },
    {
        "AIRLINECODE": "QN",
        "AIRLINENAME": "Royal Aviation"
    },
    {
        "AIRLINECODE": "QP",
        "AIRLINENAME": "Regional Air"
    },
    {
        "AIRLINECODE": "QR",
        "AIRLINENAME": "Qatar Airways"
    },
    {
        "AIRLINECODE": "QS",
        "AIRLINENAME": "Smart Wings"
    },
    {
        "AIRLINECODE": "QT",
        "AIRLINENAME": "Regional Air"
    },
    {
        "AIRLINECODE": "QU",
        "AIRLINENAME": "Uganda Air"
    },
    {
        "AIRLINECODE": "QV",
        "AIRLINENAME": "Lao Aviation"
    },
    {
        "AIRLINECODE": "QW",
        "AIRLINENAME": "Turks And Caicos National Airline"
    },
    {
        "AIRLINECODE": "QX",
        "AIRLINENAME": "Horizon Airlines"
    },
    {
        "AIRLINECODE": "QY",
        "AIRLINENAME": "European Air Transport"
    },
    {
        "AIRLINECODE": "QZ",
        "AIRLINENAME": "PT Indonesia AirAsia"
    },
    {
        "AIRLINECODE": "R2",
        "AIRLINENAME": "Orenburg"
    },
    {
        "AIRLINECODE": "R3",
        "AIRLINENAME": "Aircompany Yakutia"
    },
    {
        "AIRLINECODE": "R4",
        "AIRLINENAME": "The State Transport Company Russia"
    },
    {
        "AIRLINECODE": "R5",
        "AIRLINENAME": "Malta Air Charter"
    },
    {
        "AIRLINECODE": "R6",
        "AIRLINENAME": "Air Srpska"
    },
    {
        "AIRLINECODE": "R7",
        "AIRLINENAME": "Aserco Airlines"
    },
    {
        "AIRLINECODE": "R9",
        "AIRLINENAME": "Camai Air"
    },
    {
        "AIRLINECODE": "RA",
        "AIRLINENAME": "Royal Nepal Airlines Corp"
    },
    {
        "AIRLINECODE": "RB",
        "AIRLINENAME": "Syrian Arab Airlines"
    },
    {
        "AIRLINECODE": "RC",
        "AIRLINENAME": "Atlantic Airways Faroe Islands"
    },
    {
        "AIRLINECODE": "RD",
        "AIRLINENAME": "Alitalia Team"
    },
    {
        "AIRLINECODE": "RE",
        "AIRLINENAME": "Aer Arann Express"
    },
    {
        "AIRLINECODE": "RG",
        "AIRLINENAME": "Varig"
    },
    {
        "AIRLINECODE": "RH",
        "AIRLINENAME": "Regionnair"
    },
    {
        "AIRLINECODE": "RI",
        "AIRLINENAME": "P.T.Mandala Airlines"
    },
    {
        "AIRLINECODE": "RJ",
        "AIRLINENAME": "Royal Jordanian"
    },
    {
        "AIRLINECODE": "RK",
        "AIRLINENAME": "Air Afrique"
    },
    {
        "AIRLINECODE": "RL",
        "AIRLINENAME": "Royal Phnom Penh Airways"
    },
    {
        "AIRLINECODE": "RM",
        "AIRLINENAME": "Air Moldova I"
    },
    {
        "AIRLINECODE": "RO",
        "AIRLINENAME": "Tarom"
    },
    {
        "AIRLINECODE": "RP",
        "AIRLINENAME": "Chantangua Airlines"
    },
    {
        "AIRLINECODE": "RQ",
        "AIRLINENAME": "Kam Air"
    },
    {
        "AIRLINECODE": "RR",
        "AIRLINENAME": "Royal Air Force-38 Transport Group"
    },
    {
        "AIRLINECODE": "RS",
        "AIRLINENAME": "S Seoul"
    },
    {
        "AIRLINECODE": "RT",
        "AIRLINENAME": "Jsc Uvt Aero"
    },
    {
        "AIRLINECODE": "RU",
        "AIRLINENAME": "Tci Skyking"
    },
    {
        "AIRLINECODE": "RV",
        "AIRLINENAME": "Caspian Air Lines"
    },
    {
        "AIRLINECODE": "RW",
        "AIRLINENAME": "Ras Fluggesellschaft"
    },
    {
        "AIRLINECODE": "RX",
        "AIRLINENAME": "Regent Airways"
    },
    {
        "AIRLINECODE": "RY",
        "AIRLINENAME": "European Executive Express"
    },
    {
        "AIRLINECODE": "RZ",
        "AIRLINENAME": "Servicios Aereos Nacionales S A"
    },
    {
        "AIRLINECODE": "S2",
        "AIRLINENAME": "JetKonnect"
    },
    {
        "AIRLINECODE": "S3",
        "AIRLINENAME": "Santa Barbara Airlines"
    },
    {
        "AIRLINECODE": "S4",
        "AIRLINENAME": "Sata International"
    },
    {
        "AIRLINECODE": "S5",
        "AIRLINENAME": "Shuttle America"
    },
    {
        "AIRLINECODE": "S7",
        "AIRLINENAME": "Siberia Airlines"
    },
    {
        "AIRLINECODE": "S8",
        "AIRLINENAME": "Estonian Aviation Company"
    },
    {
        "AIRLINECODE": "S9",
        "AIRLINENAME": "SWE Aviation Europe"
    },
    {
        "AIRLINECODE": "SA",
        "AIRLINENAME": "South African Airways"
    },
    {
        "AIRLINECODE": "SB",
        "AIRLINENAME": "Air Caledonie International"
    },
    {
        "AIRLINECODE": "SC",
        "AIRLINENAME": "Shandong Air"
    },
    {
        "AIRLINECODE": "SD",
        "AIRLINENAME": "Sudan Airways"
    },
    {
        "AIRLINECODE": "SE",
        "AIRLINENAME": "XL AIRWAYS FRANCE"
    },
    {
        "AIRLINECODE": "SF",
        "AIRLINENAME": "Tassili Airlines"
    },
    {
        "AIRLINECODE": "SG",
        "AIRLINENAME": "SpiceJet"
    },
    {
        "AIRLINECODE": "SI",
        "AIRLINENAME": "Blue Islands"
    },
    {
        "AIRLINECODE": "SK",
        "AIRLINENAME": "Scandinavian Airlines System"
    },
    {
        "AIRLINECODE": "SL",
        "AIRLINENAME": "THAI LION MENTARI"
    },
    {
        "AIRLINECODE": "SM",
        "AIRLINENAME": "Sunworld Intl"
    },
    {
        "AIRLINECODE": "SN",
        "AIRLINENAME": "SN Brussels Airlines"
    },
    {
        "AIRLINECODE": "SP",
        "AIRLINENAME": "Sociedade Acoreana De Transportes"
    },
    {
        "AIRLINECODE": "SQ",
        "AIRLINENAME": "Singapore Airlines"
    },
    {
        "AIRLINECODE": "SR",
        "AIRLINENAME": "Swiss Air Transport Co"
    },
    {
        "AIRLINECODE": "SS",
        "AIRLINENAME": "Corsair International"
    },
    {
        "AIRLINECODE": "ST",
        "AIRLINENAME": "Germania"
    },
    {
        "AIRLINECODE": "SU",
        "AIRLINENAME": "Aeroflot Russian Airlines"
    },
    {
        "AIRLINECODE": "SV",
        "AIRLINENAME": "Saudi Arabian Airlines"
    },
    {
        "AIRLINECODE": "SW",
        "AIRLINENAME": "Air Namibia"
    },
    {
        "AIRLINECODE": "SX",
        "AIRLINENAME": "Sky Work Airlines"
    },
    {
        "AIRLINECODE": "SY",
        "AIRLINENAME": "Sun Country Airlines"
    },
    {
        "AIRLINECODE": "SZ",
        "AIRLINENAME": "Somon Air"
    },
    {
        "AIRLINECODE": "T0",
        "AIRLINENAME": "TACA PERU"
    },
    {
        "AIRLINECODE": "T2",
        "AIRLINENAME": "Nakima Air Service"
    },
    {
        "AIRLINECODE": "T3",
        "AIRLINENAME": "Eastern Airways"
    },
    {
        "AIRLINECODE": "T4",
        "AIRLINENAME": "Hellas Jet"
    },
    {
        "AIRLINECODE": "T5",
        "AIRLINENAME": "Turkmenistan"
    },
    {
        "AIRLINECODE": "T6",
        "AIRLINENAME": "Tavrey Aircompany"
    },
    {
        "AIRLINECODE": "T7",
        "AIRLINENAME": "Twin Jet"
    },
    {
        "AIRLINECODE": "T8",
        "AIRLINENAME": "Transportes Aereos Nuequen"
    },
    {
        "AIRLINECODE": "T9",
        "AIRLINENAME": "Varmlandsflyg"
    },
    {
        "AIRLINECODE": "TA",
        "AIRLINENAME": "Taca International Airlines"
    },
    {
        "AIRLINECODE": "TB",
        "AIRLINENAME": "Jetairfly.com"
    },
    {
        "AIRLINECODE": "TC",
        "AIRLINENAME": "Air Tanzania Corp"
    },
    {
        "AIRLINECODE": "TD",
        "AIRLINENAME": "ATLANTIS EUROPEAN AIRWAYS"
    },
    {
        "AIRLINECODE": "TE",
        "AIRLINENAME": "Lithuanian Airlines"
    },
    {
        "AIRLINECODE": "TF",
        "AIRLINENAME": "Malmo Aviation"
    },
    {
        "AIRLINECODE": "TG",
        "AIRLINENAME": "Thai Airways International"
    },
    {
        "AIRLINECODE": "TH",
        "AIRLINENAME": "British Regional Airlines"
    },
    {
        "AIRLINECODE": "TI",
        "AIRLINENAME": "KINGFISHER AIRLINES (INT)"
    },
    {
        "AIRLINECODE": "TJ",
        "AIRLINENAME": "Tradewind Aviation"
    },
    {
        "AIRLINECODE": "TK",
        "AIRLINENAME": "Turkish Airlines"
    },
    {
        "AIRLINECODE": "TL",
        "AIRLINENAME": "Airnorth Reg"
    },
    {
        "AIRLINECODE": "TM",
        "AIRLINENAME": "Lam Linhas Aereas De Mocambique"
    },
    {
        "AIRLINECODE": "TN",
        "AIRLINENAME": "Air Tahiti Nui"
    },
    {
        "AIRLINECODE": "TO",
        "AIRLINENAME": "Transavia France"
    },
    {
        "AIRLINECODE": "TP",
        "AIRLINENAME": "Air Portugal"
    },
    {
        "AIRLINECODE": "TQ",
        "AIRLINENAME": "Tandem Aero"
    },
    {
        "AIRLINECODE": "TR",
        "AIRLINENAME": "Scoot"
    },
    {
        "AIRLINECODE": "TS",
        "AIRLINENAME": "Air Transat A.T."
    },
    {
        "AIRLINECODE": "TT",
        "AIRLINENAME": "Tigerair Australia"
    },
    {
        "AIRLINECODE": "TU",
        "AIRLINENAME": "Tunis Air"
    },
    {
        "AIRLINECODE": "TV",
        "AIRLINENAME": "Virgin Expres"
    },
    {
        "AIRLINECODE": "TW",
        "AIRLINENAME": "TWAY AIR"
    },
    {
        "AIRLINECODE": "TX",
        "AIRLINENAME": "Air Caraibes"
    },
    {
        "AIRLINECODE": "TY",
        "AIRLINENAME": "Air Caledonia"
    },
    {
        "AIRLINECODE": "TZ",
        "AIRLINENAME": "Scoot"
    },
    {
        "AIRLINECODE": "U2",
        "AIRLINENAME": "Easyjet Airline Company"
    },
    {
        "AIRLINECODE": "U4",
        "AIRLINENAME": "Buryat Airlines"
    },
    {
        "AIRLINECODE": "U5",
        "AIRLINENAME": "USA 3000 Airlines"
    },
    {
        "AIRLINECODE": "U6",
        "AIRLINENAME": "Ural Airlines"
    },
    {
        "AIRLINECODE": "U7",
        "AIRLINENAME": "Air Uganda"
    },
    {
        "AIRLINECODE": "U8",
        "AIRLINENAME": "Armavia"
    },
    {
        "AIRLINECODE": "U9",
        "AIRLINENAME": "Tatarstan"
    },
    {
        "AIRLINECODE": "UA",
        "AIRLINENAME": "United Airlines"
    },
    {
        "AIRLINECODE": "UB",
        "AIRLINENAME": "Myanma Airway"
    },
    {
        "AIRLINECODE": "UC",
        "AIRLINENAME": "Linea Aerea Del Cobre"
    },
    {
        "AIRLINECODE": "UD",
        "AIRLINENAME": "Hex Air"
    },
    {
        "AIRLINECODE": "UE",
        "AIRLINENAME": "Transeuropean Airlines"
    },
    {
        "AIRLINECODE": "UF",
        "AIRLINENAME": "Ukranian-Mediterranean Airlines"
    },
    {
        "AIRLINECODE": "UG",
        "AIRLINENAME": "Tuninter"
    },
    {
        "AIRLINECODE": "UH",
        "AIRLINENAME": "US Helicopter"
    },
    {
        "AIRLINECODE": "UI",
        "AIRLINENAME": "Auric Air"
    },
    {
        "AIRLINECODE": "UJ",
        "AIRLINENAME": "Aerosanta Airlines"
    },
    {
        "AIRLINECODE": "UK",
        "AIRLINENAME": "Vistara"
    },
    {
        "AIRLINECODE": "UL",
        "AIRLINENAME": "Sri Lankan Airlines"
    },
    {
        "AIRLINECODE": "UM",
        "AIRLINENAME": "Air Zimbabwe"
    },
    {
        "AIRLINECODE": "UN",
        "AIRLINENAME": "Transaero"
    },
    {
        "AIRLINECODE": "UO",
        "AIRLINENAME": "Hong Kong Express Airways"
    },
    {
        "AIRLINECODE": "UP",
        "AIRLINENAME": "Bahamasair"
    },
    {
        "AIRLINECODE": "UQ",
        "AIRLINENAME": "O'Connor Airlines"
    },
    {
        "AIRLINECODE": "US",
        "AIRLINENAME": "US Airways"
    },
    {
        "AIRLINECODE": "UT",
        "AIRLINENAME": "UT Air"
    },
    {
        "AIRLINECODE": "UU",
        "AIRLINENAME": "Air Austral"
    },
    {
        "AIRLINECODE": "UV",
        "AIRLINENAME": "Helicopteros Del Sureste"
    },
    {
        "AIRLINECODE": "UW",
        "AIRLINENAME": "Perimeter Aviation"
    },
    {
        "AIRLINECODE": "UX",
        "AIRLINENAME": "Air Europa"
    },
    {
        "AIRLINECODE": "UY",
        "AIRLINENAME": "Cameroon Airlines"
    },
    {
        "AIRLINECODE": "UZ",
        "AIRLINENAME": "U. P. Air"
    },
    {
        "AIRLINECODE": "V0",
        "AIRLINENAME": "Conviasa"
    },
    {
        "AIRLINECODE": "V2",
        "AIRLINENAME": "Vision Airlines"
    },
    {
        "AIRLINECODE": "V3",
        "AIRLINENAME": "Carpatair"
    },
    {
        "AIRLINECODE": "V4",
        "AIRLINENAME": "Vieques Air Link"
    },
    {
        "AIRLINECODE": "V5",
        "AIRLINENAME": "Ventura Airconnect"
    },
    {
        "AIRLINECODE": "V6",
        "AIRLINENAME": "VI AIR LINK"
    },
    {
        "AIRLINECODE": "V7",
        "AIRLINENAME": "Volotea"
    },
    {
        "AIRLINECODE": "V8",
        "AIRLINENAME": "TAPSA Aviacion"
    },
    {
        "AIRLINECODE": "V9",
        "AIRLINENAME": "Bashkir Airlines"
    },
    {
        "AIRLINECODE": "VA",
        "AIRLINENAME": "Virgin Australia"
    },
    {
        "AIRLINECODE": "VB",
        "AIRLINENAME": "Maersk Air Lt"
    },
    {
        "AIRLINECODE": "VC",
        "AIRLINENAME": "Servivensa"
    },
    {
        "AIRLINECODE": "VE",
        "AIRLINENAME": "Aerovias Venezulanas"
    },
    {
        "AIRLINECODE": "VF",
        "AIRLINENAME": "Nevis Express"
    },
    {
        "AIRLINECODE": "VG",
        "AIRLINENAME": "VLM Airlines"
    },
    {
        "AIRLINECODE": "VH",
        "AIRLINENAME": "Aeropostal Alas De Venezue"
    },
    {
        "AIRLINECODE": "VJ",
        "AIRLINENAME": "VietJet Air"
    },
    {
        "AIRLINECODE": "VK",
        "AIRLINENAME": "Virgin Express Ireland."
    },
    {
        "AIRLINECODE": "VL",
        "AIRLINENAME": "Med-View Airline"
    },
    {
        "AIRLINECODE": "VM",
        "AIRLINENAME": "Regional Airlines"
    },
    {
        "AIRLINECODE": "VN",
        "AIRLINENAME": "Vietnam Airlines"
    },
    {
        "AIRLINECODE": "VO",
        "AIRLINENAME": "Tyrolean Air"
    },
    {
        "AIRLINECODE": "VP",
        "AIRLINENAME": "Vasp Brazilian Airlines"
    },
    {
        "AIRLINECODE": "VQ",
        "AIRLINENAME": "Novo Air"
    },
    {
        "AIRLINECODE": "VR",
        "AIRLINENAME": "Transportes Aereos De Cabo Verde"
    },
    {
        "AIRLINECODE": "VS",
        "AIRLINENAME": "Virgin Atlantic Airways"
    },
    {
        "AIRLINECODE": "VT",
        "AIRLINENAME": "Air Tahiti"
    },
    {
        "AIRLINECODE": "VU",
        "AIRLINENAME": "Air Ivoire"
    },
    {
        "AIRLINECODE": "VV",
        "AIRLINENAME": "Aerosvit Airlines"
    },
    {
        "AIRLINECODE": "VW",
        "AIRLINENAME": "Aeromar Airlines"
    },
    {
        "AIRLINECODE": "VX",
        "AIRLINENAME": "Virgin America"
    },
    {
        "AIRLINECODE": "VY",
        "AIRLINENAME": "Vueling Airlines"
    },
    {
        "AIRLINECODE": "VZ",
        "AIRLINENAME": "Air Tours Intl Airways"
    },
    {
        "AIRLINECODE": "W2",
        "AIRLINENAME": "World Airline"
    },
    {
        "AIRLINECODE": "W3",
        "AIRLINENAME": "Arik Air"
    },
    {
        "AIRLINECODE": "W4",
        "AIRLINENAME": "LC Perú"
    },
    {
        "AIRLINECODE": "W5",
        "AIRLINENAME": "Mahan Air"
    },
    {
        "AIRLINECODE": "W6",
        "AIRLINENAME": "Wizz Air"
    },
    {
        "AIRLINECODE": "W7",
        "AIRLINENAME": "Sayakhat Air Company"
    },
    {
        "AIRLINECODE": "W9",
        "AIRLINENAME": "Air Bagan"
    },
    {
        "AIRLINECODE": "WA",
        "AIRLINENAME": "Klm Cityhoppe"
    },
    {
        "AIRLINECODE": "WB",
        "AIRLINENAME": "Rwandair Express"
    },
    {
        "AIRLINECODE": "WC",
        "AIRLINENAME": "Islena Airl"
    },
    {
        "AIRLINECODE": "WD",
        "AIRLINENAME": "Halisa Air"
    },
    {
        "AIRLINECODE": "WE",
        "AIRLINENAME": "Thai Smile Airways"
    },
    {
        "AIRLINECODE": "WF",
        "AIRLINENAME": "Wideroes Flyveselskap A\/S"
    },
    {
        "AIRLINECODE": "WG",
        "AIRLINENAME": "SUNWING AIRLINES INC"
    },
    {
        "AIRLINECODE": "WH",
        "AIRLINENAME": "China N West"
    },
    {
        "AIRLINECODE": "WI",
        "AIRLINENAME": "WHITE AIRWAYS S.A."
    },
    {
        "AIRLINECODE": "WJ",
        "AIRLINENAME": "Labrador Airways"
    },
    {
        "AIRLINECODE": "WK",
        "AIRLINENAME": "American Falcon S.A."
    },
    {
        "AIRLINECODE": "WL",
        "AIRLINENAME": "Aeroperlas"
    },
    {
        "AIRLINECODE": "WM",
        "AIRLINENAME": "Windward Islands Airways Intl"
    },
    {
        "AIRLINECODE": "WN",
        "AIRLINENAME": "Southwest Airlines Dallas"
    },
    {
        "AIRLINECODE": "WP",
        "AIRLINENAME": "Island Air"
    },
    {
        "AIRLINECODE": "WQ",
        "AIRLINENAME": "Romavia"
    },
    {
        "AIRLINECODE": "WR",
        "AIRLINENAME": "Royal Tongan"
    },
    {
        "AIRLINECODE": "WS",
        "AIRLINENAME": "Westjet Airlines"
    },
    {
        "AIRLINECODE": "WT",
        "AIRLINENAME": "Nigeria Airways"
    },
    {
        "AIRLINECODE": "WV",
        "AIRLINENAME": "Aero Vip"
    },
    {
        "AIRLINECODE": "WW",
        "AIRLINENAME": "WOW AIR"
    },
    {
        "AIRLINECODE": "WX",
        "AIRLINENAME": "Cityjet"
    },
    {
        "AIRLINECODE": "WY",
        "AIRLINENAME": "Oman Aviation"
    },
    {
        "AIRLINECODE": "WZ",
        "AIRLINENAME": "Acvila Air"
    },
    {
        "AIRLINECODE": "X3",
        "AIRLINENAME": "TUIfly"
    },
    {
        "AIRLINECODE": "X5",
        "AIRLINENAME": "Cronus Air"
    },
    {
        "AIRLINECODE": "X7",
        "AIRLINENAME": "Exec Air Inc. of Naples"
    },
    {
        "AIRLINECODE": "X9",
        "AIRLINENAME": "Khors Air Company"
    },
    {
        "AIRLINECODE": "XC",
        "AIRLINENAME": "Corendon Airlines"
    },
    {
        "AIRLINECODE": "XF",
        "AIRLINENAME": "Vladivostok Air"
    },
    {
        "AIRLINECODE": "XG",
        "AIRLINENAME": "SunExpress Deutschland"
    },
    {
        "AIRLINECODE": "XH",
        "AIRLINENAME": "Hndlg Angent"
    },
    {
        "AIRLINECODE": "XI",
        "AIRLINENAME": "Air India Expres (Intl)"
    },
    {
        "AIRLINECODE": "XJ",
        "AIRLINENAME": "Thai AirAsia X"
    },
    {
        "AIRLINECODE": "XK",
        "AIRLINENAME": "Compagnie Corse Mediterranee"
    },
    {
        "AIRLINECODE": "XL",
        "AIRLINENAME": "Lanecuador"
    },
    {
        "AIRLINECODE": "XM",
        "AIRLINENAME": "Alitalia Express"
    },
    {
        "AIRLINECODE": "XN",
        "AIRLINENAME": "Xpress Air"
    },
    {
        "AIRLINECODE": "XO",
        "AIRLINENAME": "China Xinjiang Airl"
    },
    {
        "AIRLINECODE": "XQ",
        "AIRLINENAME": "SunExpress"
    },
    {
        "AIRLINECODE": "XR",
        "AIRLINENAME": "Skywest Airlines"
    },
    {
        "AIRLINECODE": "XT",
        "AIRLINENAME": "Klm Exel"
    },
    {
        "AIRLINECODE": "xv",
        "AIRLINENAME": "BVI Airways"
    },
    {
        "AIRLINECODE": "XW",
        "AIRLINENAME": "NokScoot"
    },
    {
        "AIRLINECODE": "XY",
        "AIRLINENAME": "FLYNAS"
    },
    {
        "AIRLINECODE": "XZ",
        "AIRLINENAME": "SOUTH AFRICAN EXPRESS"
    },
    {
        "AIRLINECODE": "Y2",
        "AIRLINENAME": "Alliance Air"
    },
    {
        "AIRLINECODE": "Y4",
        "AIRLINENAME": "Eagle Aviatio"
    },
    {
        "AIRLINECODE": "Y7",
        "AIRLINENAME": "JSC Airline Taimyr"
    },
    {
        "AIRLINECODE": "Y8",
        "AIRLINENAME": "Passaredo Transportes"
    },
    {
        "AIRLINECODE": "Y9",
        "AIRLINENAME": "Trans Air Congo"
    },
    {
        "AIRLINECODE": "YB",
        "AIRLINENAME": "South African Express Airlines"
    },
    {
        "AIRLINECODE": "YC",
        "AIRLINENAME": "Yamal Airlines"
    },
    {
        "AIRLINECODE": "YD",
        "AIRLINENAME": "Gomelavia"
    },
    {
        "AIRLINECODE": "YE",
        "AIRLINENAME": "YAN AIR"
    },
    {
        "AIRLINECODE": "YI",
        "AIRLINENAME": "Air Sunshine"
    },
    {
        "AIRLINECODE": "YJ",
        "AIRLINENAME": "National Air"
    },
    {
        "AIRLINECODE": "YK",
        "AIRLINENAME": "Avia Traffic Company"
    },
    {
        "AIRLINECODE": "YM",
        "AIRLINENAME": "Montenegro Airlines"
    },
    {
        "AIRLINECODE": "YN",
        "AIRLINENAME": "Air Creebec"
    },
    {
        "AIRLINECODE": "YO",
        "AIRLINENAME": "Heli Air Monaco"
    },
    {
        "AIRLINECODE": "YP",
        "AIRLINENAME": "Aero Lloyd"
    },
    {
        "AIRLINECODE": "YQ",
        "AIRLINENAME": "Polet Airlines"
    },
    {
        "AIRLINECODE": "YR",
        "AIRLINENAME": "Scenic Air Lines"
    },
    {
        "AIRLINECODE": "YS",
        "AIRLINENAME": "Proteus"
    },
    {
        "AIRLINECODE": "YT",
        "AIRLINENAME": "YETI AIRLINES"
    },
    {
        "AIRLINECODE": "YU",
        "AIRLINENAME": "EUROATLANTIC AIRWAYS"
    },
    {
        "AIRLINECODE": "YV",
        "AIRLINENAME": "Mesa Aviation Services"
    },
    {
        "AIRLINECODE": "YW",
        "AIRLINENAME": "Air Nostrum"
    },
    {
        "AIRLINECODE": "YX",
        "AIRLINENAME": "Midwest Express Airlines"
    },
    {
        "AIRLINECODE": "Z2",
        "AIRLINENAME": "AirAsia Zest"
    },
    {
        "AIRLINECODE": "Z3",
        "AIRLINENAME": "Promech"
    },
    {
        "AIRLINECODE": "Z4",
        "AIRLINENAME": "Tayfunair"
    },
    {
        "AIRLINECODE": "Z5",
        "AIRLINENAME": "Gmg Airlines"
    },
    {
        "AIRLINECODE": "Z6",
        "AIRLINENAME": "Dnieproavia Joint Stock Aviation Company"
    },
    {
        "AIRLINECODE": "Z7",
        "AIRLINENAME": "Flyafrica.com"
    },
    {
        "AIRLINECODE": "Z8",
        "AIRLINENAME": "Pulkovo Aviation Enterprise"
    },
    {
        "AIRLINECODE": "Z9",
        "AIRLINENAME": "JSC BEK AIR"
    },
    {
        "AIRLINECODE": "ZA",
        "AIRLINENAME": "Sky Angkor Airlines"
    },
    {
        "AIRLINECODE": "ZB",
        "AIRLINENAME": "Monarch Airline"
    },
    {
        "AIRLINECODE": "ZC",
        "AIRLINENAME": "KORONGO AIRLINES"
    },
    {
        "AIRLINECODE": "ZE",
        "AIRLINENAME": "EA JET"
    },
    {
        "AIRLINECODE": "ZG",
        "AIRLINENAME": "Groznyy Avia"
    },
    {
        "AIRLINECODE": "ZH",
        "AIRLINENAME": "Shenzhen Airlines"
    },
    {
        "AIRLINECODE": "ZI",
        "AIRLINENAME": "Aigle Azur Transport Ariens"
    },
    {
        "AIRLINECODE": "ZJ",
        "AIRLINENAME": "Zambezi Airlines"
    },
    {
        "AIRLINECODE": "ZK",
        "AIRLINENAME": "Great Lakes"
    },
    {
        "AIRLINECODE": "ZL",
        "AIRLINENAME": "Hazelton Air Services Pty"
    },
    {
        "AIRLINECODE": "ZM",
        "AIRLINENAME": "AIR MANAS"
    },
    {
        "AIRLINECODE": "ZN",
        "AIRLINENAME": "Eagle Air"
    },
    {
        "AIRLINECODE": "ZO",
        "AIRLINENAME": "Zoom Air"
    },
    {
        "AIRLINECODE": "ZP",
        "AIRLINENAME": "Air St Thomas"
    },
    {
        "AIRLINECODE": "ZR",
        "AIRLINENAME": "Muk Air"
    },
    {
        "AIRLINECODE": "ZS",
        "AIRLINENAME": "Sama Airlines"
    },
    {
        "AIRLINECODE": "ZT",
        "AIRLINENAME": "Air Zambezi"
    },
    {
        "AIRLINECODE": "ZV",
        "AIRLINENAME": "Air Midwest"
    },
    {
        "AIRLINECODE": "ZW",
        "AIRLINENAME": "Air Wisconsin"
    },
    {
        "AIRLINECODE": "ZX",
        "AIRLINENAME": "Air British Columbia"
    },
    {
        "AIRLINECODE": "ZY",
        "AIRLINENAME": "Ada Air"
    }
]
