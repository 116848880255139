import React, { FC, useEffect, useState, useCallback } from "react";
import logo from "images/logo.png";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import Logo from "shared/Logo/Logo";
// import { useQueryClient } from "@tanstack/react-query";
import { getUser } from "utils/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "config/firebase-config";
// import MyTimer from "components/Timer/Timer";
import { foundUser } from "app/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";
import inr from "../../images/currency/India.png"
import usd from "../../images/currency/US.png"
import cad from "../../images/currency/Canada.png"
import { TiContacts } from "react-icons/ti";
import OutsideClickHandler from "utils/OutsideClickHandler";
import { gtag } from 'ga-gtag'
import { gtag_report_conversion } from "googleAnalytics/googleAds";

export interface MainNav1Props {
  className?: string;
}

const currencyList = [
  {
    name: 'USD',
    symbol: '$',
    country: "US",
    icon: usd,
  },
  {
    name: 'CAD',
    symbol: 'C$',
    country: "CA",
    icon: cad,
  },
  {
    name: 'USD',
    symbol: '$',
    country: "US",
    icon: usd,
  }
]

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  // const queryClient = useQueryClient()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [urlSearchParams]: any = useSearchParams()
  let curren = currencyList.filter((curr: any) => curr.name === urlSearchParams.get('cur'))[0]
  const { user, userLocation } = useSelector((state: any) => state.userReducer);
  const { currency } = useSelector((state: any) => state.appReducer);
  const [isCurrencyDropDownOpen, setIsCurrencyDropDownOpen] = useState(false);
  const [isCurrencyDropDownOpenMobile, setIsCurrencyDropDownOpenMobile] = useState(false);
  const [selectedCurr, setSelectedCurr] = useState(curren || currency)

  const handleLogout = useCallback(() => {
    signOut(auth).then(() => {
      import('../../app/actions/userActions').then((action: any) => dispatch(action.removeUser()))
      localStorage.removeItem('@auth-token')
      // queryClient.invalidateQueries({ queryKey: ['getUser'] })
      navigate('/')
    }).catch(error => {
      console.log('/ascsa', error);
    })
  }, [])

  // const { data: userData, isLoading } = useQuery(['getUser'], getUser, {
  //   staleTime: (100 * 1000),
  //   // refetchInterval: 60000,
  //   onSuccess: () => {

  //     if (localStorage.getItem('@auth-token') !== null && userData) dispatch(foundUser(userData))
  //   },
  //   keepPreviousData: true
  // });

  const fetchUser = useCallback(async () => {
    const userData = await getUser()
    if (userData) {
      dispatch(foundUser(userData))
    }
  }, [])

  const getCurrency = async () => {
    if (window.location.pathname === "/") {
      if (urlSearchParams.get('cur')) {
        console.log({ curren });
        if (curren) {

          import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curren)))
        } else {
          if (userLocation) {
            let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === userLocation?.country?.toLowerCase())[0]
            import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
            setSelectedCurr(curr)
            navigate(`/?cur=${curr.name}`)
          } else {
            let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
            let countryData = await data.json();
            import('../../app/actions/userActions').then(action => dispatch(action.getUserLocation(countryData)))
            let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === countryData?.country?.toLowerCase())[0]
            import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
            setSelectedCurr(curr)
            navigate(`/?cur=${curr.name}`)
          }
        }
      } else {
        if (userLocation) {
          let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === userLocation?.country?.toLowerCase())[0]
          import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
          setSelectedCurr(curr)
          navigate(`/?cur=${curr.name}`)
        } else {
          let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
          let countryData = await data.json();
          import('../../app/actions/userActions').then(action => dispatch(action.getUserLocation(countryData)))
          let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === countryData?.country?.toLowerCase())[0]
          import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
          setSelectedCurr(curr)
          navigate(`/?cur=${curr.name}`)
        }
      }

    }
    else {
      if (userLocation) {
        let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === userLocation?.country?.toLowerCase())[0]
        import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
        setSelectedCurr(curr)
        // navigate(`/?curr=${curr.name}`)
      } else {
        let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
        let countryData = await data.json();
        import('../../app/actions/userActions').then(action => dispatch(action.getUserLocation(countryData)))
        let curr = currencyList.filter((country: any) => country?.country?.toLowerCase() === countryData?.country?.toLowerCase())[0]
        import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curr)))
        setSelectedCurr(curr)
        // navigate(`/?curr=${curr.name}`)
      }
    }
  };


  useEffect(() => {
    fetchUser();
    getCurrency()
  }, [])


  // useEffect(() => {
  //   getCurrency()
  // }, [])

  // function gtag_report_conversion(url: any) {
  //   var callback = function () {
  //     if (typeof (url) != 'undefined') {
  //       window.location = url;
  //     }
  //   };
  //   gtag('event', 'conversion', {
  //     'send_to': 'AW-11100115796/rsTgCPO08fgDENSm-awp',
  //     'event_callback': callback
  //   });
  //   return false;
  // }


  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="sm:px-4 w-[95%] mx-auto py-3 sm:py-4 lg:py-5 relative flex justify-between items-center">


        <div className="flex justify-between flex-1 items-center space-x-4 sm:space-x-10">
          <Logo currency={currency} img={logo} className="sm:w-[200px] w-[150px]" />
          <Navigation />
          <div className="hidden xl:flex flex-1 items-center space-x-0.5 justify-end">
            <div className="flex items-center justify-center gap-2 text-[#0a4293] w-[175px]">
              <TiContacts className="text-3xl" />
              <div className="flex flex-col justify-center">
                <p className="text-[11px] pl-[2px]">24/7 Helpline</p>
                <a className='text-[16px] font-medium w-max pl-[0px]'
                  onClick={(e) =>
                    gtag_report_conversion(`tel:${currency?.country === "US" ? "+91 11 420 77777 " : '+1 8018827740'}`)

                  }
                  href={`tel:${currency?.country === "US" ? "+91 011 420 77777 " : '+1 8018827740'}`}>
                  {currency?.country === "US" ? "011 42077777" : "+1 8018827740"}
                </a>
              </div>
            </div>
            <div className="px-1" />
            <div className="relative hover:cursor-pointer w-max" onClick={() => setIsCurrencyDropDownOpen(!isCurrencyDropDownOpen)}>
              <div className="mr-3 ml-2 flex items-center gap-2 ">
                <div className="">
                  <img src={selectedCurr?.icon || usd} alt="" />
                </div>
                <div className="text-xs font-medium w-max mr-3">{selectedCurr?.name} ({selectedCurr?.symbol})/{selectedCurr?.country}</div>
                <IoMdArrowDropdown className='text-xl absolute right-1' />
              </div>
              {isCurrencyDropDownOpen ?
                <OutsideClickHandler
                  onClick={() => setIsCurrencyDropDownOpen(false)}
                >
                  <div className='absolute z-50 bg-white -left-2 top-9 max-h-52 rounded-md w-52 shadow-lg border border-gray-400'  >
                    {currencyList.map((country: any, idx: any) => {
                      return <>
                        <Link
                          to={`/?cur=${country?.name}`}
                          target="_blank"
                          referrerPolicy="no-referrer"
                          className={`w-full flex py-2 rounded-b-md hover:bg-gray-100 ${selectedCurr?.name === country?.name && 'bg-blue-100'}`}
                        >
                          <div className="mr-6 ml-2 flex items-center gap-2 ">
                            <div className="">
                              <img src={country.icon} alt="" />
                            </div>
                            <p className="text-xs font-medium w-max mr-3">{country.name} ({country.symbol})/{country.country}</p>
                          </div>
                        </Link>
                        {/* <Link
                          to={`/?curr=${country?.name}`}
                          target="_blank"
                          referrerPolicy="no-referrer"
                          // onClick={() => {
                          //   setSelectedCurr(country);
                          //   import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(country)))

                          // }}
                          className={`bg-black ${selectedCurr?.name === country?.name && 'bg-blue-100'} 
                        ${idx === 0 ? "rounded-t-md" : ''}
                        ${idx === currencyList.length - 1 ? "rounded-b-md" : ''}
                          hover:bg-primary-6000
                        hover:text-white text-sm hover:cursor-pointer px-2 font-medium py-2 ${idx !== currencyList.length - 1 && "border-b"}`}
                          key={idx}>
                          <div className="mr-6 ml-2 flex items-center gap-2 ">
                            <div className="">
                              <img src={country.icon} alt="" />
                            </div>
                            <p className="text-xs font-medium w-max mr-3">{country.name} ({country.symbol})/{country.country}</p>
                          </div>
                        </Link> */}
                      </>
                    })}
                  </div>
                </OutsideClickHandler>
                : null
              }
            </div>

            {user &&
              <div className="w-px h-6 bg-gray-400 mr-6"></div>
            }
            {!user ?
              <ButtonPrimary className="rounded-md" href="/login">Login/Signup</ButtonPrimary>
              :
              <>
                <h4 className="pr-4 pl-2 w-max">
                  {user!.name}
                </h4>
                <ButtonPrimary className="ml-8 rounded-md" onClick={handleLogout}>Logout</ButtonPrimary>
              </>
            }

          </div>
        </div>







        {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}

        <div className="flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="flex xl:hidden items-center">
            <div className="relative hover:cursor-pointer w-max" onClick={() => setIsCurrencyDropDownOpenMobile(!isCurrencyDropDownOpenMobile)}>
              <div className="sm:mr-3 mr-2.5 ml-1 sm:ml-2 flex items-center gap-2 ">
                <div className="">
                  <img src={selectedCurr?.icon || usd} alt="" />
                </div>
                <div className="text-xs font-medium w-max mr-3">{selectedCurr?.name} ({selectedCurr?.symbol})/{selectedCurr?.country}</div>
                <IoMdArrowDropdown className='text-xl absolute right-1' />
              </div>
              {isCurrencyDropDownOpenMobile ?
                <OutsideClickHandler
                  onClick={() => setIsCurrencyDropDownOpenMobile(false)}
                >
                  <div className='absolute z-[70] bg-white -left-2 top-9 max-h-52 rounded-md w-40 sm:w-48 max-w-60 shadow-lg border border-gray-400' onClick={() => console.log('ajscbijsboijbj')}>
                    {currencyList.map((country: any, idx: any) => {
                      return <>
                        <Link
                          to={`/?cur=${country?.name}`}
                          target="_blank"
                          referrerPolicy="no-referrer"
                          className={`w-full flex p-2 rounded-b-md hover:bg-gray-100 ${selectedCurr?.name === country?.name && 'bg-blue-100'}`}
                          key={idx}>
                          <div className="sm:mr-4 sm:ml-2 flex items-center gap-2 ">
                            <img src={country.icon} className="w-[15px] h-[15px]" alt="" />
                            <p className="text-xs font-medium w-max min-w-fit">{country.name} ({country.symbol})/{country.country}</p>
                          </div>
                        </Link>
                      </>
                    })}
                  </div>
                </OutsideClickHandler>
                : null
              }
            </div>
            <div className="px-0.5 sm:flow-root hidden" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div >
  );
};

export default MainNav1;
