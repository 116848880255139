import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [{ name: "Facebook", icon: "lab la-facebook-square", href: "https://www.facebook.com/aerotrav/",indiaHref:"https://www.facebook.com/aerotravin?mibextid=LQQJ4d" },
{ name: "Instagram", icon: "lab la-instagram", href: "https://www.instagram.com/aerotravus/",indiaHref:"https://instagram.com/aerotravin?igshid=YmMyMTA2M2Y=" },
{ name: "Linkedin", icon: "lab la-linkedin", href: "https://www.linkedin.com/company/aerotrav",indiaHref:"https://www.linkedin.com/company/aerotrav" },
{ name: "Twitter", icon: "lab la-twitter", href: "https://twitter.com/AeroTrav",indiaHref:"https://twitter.com/aerotravin?s=21&t=2uZXxDSSC06JEGklt7BJSw" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const {currency} =useSelector((state:any)=>state.appReducer)
  const renderItem = (item: SocialType, index: number) => {
    if(currency?.country==="IN"&&item.indiaHref==="")
          return <></>
    return (
      <a
        href={currency?.country==="IN"?item.indiaHref:item.href}
        className="flex items-center text-3xl text-white dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className={item.icon}></i>
      </a>
    );
  };

  return (
    <div className='flex sm:gap-6 gap-4 sm:pl-6 sm:mt-4 mt-1 sm:justify-start justify-center'>
      {socials.map(renderItem)}
    </div>
  );
};

export default React.memo(SocialsList1);
