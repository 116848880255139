export const searchActionTypes = {
    PLACE_SELECTED: "PLACE_SELECTED",
    JOURNEY_TYPE: 'JOURNEY_TYPE',
    TOKEN_ID: "TOKEN_ID",
    FLIGHT_CABIN: 'FLIGHT_CABIN',
    PASSENGER_COUNT: 'PASSENGER_COUNT',
    GET_DATE: 'GET_DATE',
    START_TIMER: "START_TIMER",
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    SESSION_STARTED: 'SESSION_STARTED',
    END_TIMER: 'END_TIMER',
    FLIGHTS_LOADING: 'FLIGHTS_LOADING',
    SEGMENT_SELECTED: 'SEGMENT_SELECTED',
    SEGMENT_ADDED: 'SEGMENT_ADDED',
    DATES_SEGMENT_SELECTED: "DATES_SEGMENT_SELECTED",
    DELETE_SEGMENTS: "DELETE_SEGMENTS",
    TRACE_ID: "TRACE_ID",
    FLIP_DATA: 'FLIP_DATA',
    MULTISEARCH_VALUES: 'MULTISEARCH_VALUES',
    DELETED_FULLNAMES: 'DELETED_FULLNAMES',
    MOBILE_FLIGHT_LOADING_TRUE: "MOBILE_FLIGHT_LOADING_TRUE",
    MOBILE_FLIGHT_LOADING_FALSE: "MOBILE_FLIGHT_LOADING_FALSE",
    TOOGLE_DIRECT_FLIGHTS: 'TOOGLE_DIRECT_FLIGHTS',
    GET_PREFERRED_AIRLINES: 'GET_PREFERRED_AIRLINES',
    REFRESH_RESULTS: 'REFRESH_RESULTS'
}