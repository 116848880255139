import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { getUser, signInWithEmail } from "utils/auth";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "config/firebase-config";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
    function: 'signInWithGoogle'
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const googleProvider = new GoogleAuthProvider();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
    name: '',
    confirmPassword: '',
  })

  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
  })


  const checkUserInDb = async (uid: any) => {
    console.log({ uid });

    const userRef = doc(db, 'Users', uid);
    const docs = await getDoc(userRef);
    console.log('docs Data', docs.data());

    if (!docs.data()) {
      return false
    } else {
      return docs.data();
    }
  }

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
      let countryData = await data.json();
      try {
        localStorage.setItem('@auth-token', user.uid)
      } catch (error) {
        console.log(error);
      }
      const q = await checkUserInDb(user.uid);
      if (!q) {
        await setDoc(doc(db, "Users", user?.uid), {
          id: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          createdAt: serverTimestamp(),
          country: countryData?.country
        });
      }
      checkUserInDb(user.uid)
        .then((res: any) => {
          import('../../app/actions/userActions').then(userActions => {
            return dispatch(userActions.foundUser(res))
          })
          setValidationErrors({ ...validationErrors, email: '' });
          // setIsOpen(false)
          if (res) {
            navigate('/')
            console.log('signed in');
          }
          else
            setValidationErrors({ ...validationErrors, email: 'User Not Found' });
        })
      // setIsOpen(false)
    } catch (err) {
      console.error(err);
    }
  };

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  const signUpWithEmail = async (data: any) => {
    const { email, password } = data;
    return createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const { uid } = response.user;
        console.log({ response });
        return uid;
      })
      .catch(error => {
        return error
      });
  }

  const setUser = async (uid: any, userData: any) => {
    if (uid === null) {
      return false
    }
    const { email, name } = userData;
    let data = {
      id: uid,
      name,
      email: email.toLowerCase(),
      country: 'unavailable',
      createdAt: new Date(),
      profilePic: ''
    };
    const res = await addUser(data);
    return res;
  }

  const addUser = async (data: any) => {
    return await setDoc(doc(db, "Users", data.id), data).then(docRef => {
      console.log("Document Field has been updated successfully");
      return 'User Added'
    }).catch(error => {
      console.log(error);
      return error
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    validateData();
    await signUp();
  }

  const signUp = async () => {
    if (state.confirmPassword !== state.password) {
      setValidationErrors(old => { return { ...old, confirmPassword: 'Password does not match.' } })
      return
    }
    setLoading(true)
    try {
      const uid = await signUpWithEmail({ email: state.email, password: state.password });
      await setUser(uid, { email: state.email, name: state.name });
      // const res = await setUser(uid, { email: state.email, name: state.name });
      // const userData = await signInWithEmail({ email: state.email, password: state.password });
      await signInWithEmail({ email: state.email, password: state.password });
      await queryClient.invalidateQueries({ queryKey: ['getUser'] })
      const user = await getUser();
      import('../../app/actions/userActions').then((action: any) => dispatch(action.foundUser(user)));
      if (localStorage.getItem('@auth-token')) {
        navigate('/')
      }
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.log('outer error', error);
    }
  }



  const handleChange = (name: string, value: string) => {
    setState({ ...state, [name]: value })
  }

  const validateData = async () => {
    if (!emailRegex.test(state.email)) {
      setValidationErrors(old => { return { ...old, email: 'Incorrect Email' } })
    } else {
      setValidationErrors(old => { return { ...old, email: '' } })
    }
    if (!passRegex.test(state.password)) {
      setValidationErrors(old => { return { ...old, password: 'Password must contain minimum eight characters, at least one letter, one number and one special character.' } })
    } else {
      setValidationErrors(old => { return { ...old, password: '' } })
    }
    if (state.confirmPassword !== state.password) {
      setValidationErrors(old => { return { ...old, confirmPassword: 'Password does not match.' } })
    } else {
      setValidationErrors(old => { return { ...old, confirmPassword: '' } })
    }
    if (state.name === '') {
      setValidationErrors(old => { return { ...old, name: 'Name cannot be empty.' } })
    } else {
      setValidationErrors(old => { return { ...old, name: '' } })
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                onClick={(e: any) => {
                  e.preventDefault()
                  if (item?.function === 'signInWithGoogle') signInWithGoogle()
                }}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                className="mt-1"
                onChange={(e => handleChange(e.target.name, e.target.value))}
                value={state.name}
              />
              {validationErrors.name !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.name}</label>
              }
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name='email'
                onChange={(e => handleChange(e.target.name, e.target.value))}
                value={state.email}
                placeholder="example@example.com"
                className="mt-1"
              />
              {validationErrors.email !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.email}</label>
              }
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password"
                value={state.password}
                onChange={(e => handleChange(e.target.name, e.target.value))}
                name='password'
                className="mt-1" />
              {validationErrors.password !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.password}</label>
              }
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password"
                name='confirmPassword'
                value={state.confirmPassword}
                onChange={(e => handleChange(e.target.name, e.target.value))}
                className="mt-1" />
              {validationErrors.confirmPassword !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.confirmPassword}</label>
              }
            </label>
            {loading ?
              <ButtonPrimary disabled>
                <CircularProgress size={25} style={{ color: '#fff' }} />
              </ButtonPrimary>
              :
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            }
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
