import { lazy, Suspense, useEffect } from 'react'
// import SectionHero from "components/SectionHero/SectionHero";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";

// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import { TaxonomyType } from "data/types";
import './styles/pageHomeStyles.css'
import { Helmet } from 'react-helmet';
import EaseSteps from './HomeComponents/EasySteps/EaseSteps';
import NewsLetter from './HomeComponents/NewsLetter/NewsLetter';
import Hero from './HomeComponents/Hero/Hero';
import Loading from 'shared/Loading/Loading';
import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TravelAgency } from 'schema-dts';
import { JsonLd } from "react-schemaorg";


const Destinations = lazy(() => import('components/Destinations/Destinations'))
const Features = lazy(() => import("components/Features/Features"));

function PageHome() {
  // const matches = useMediaQuery('(max-width:1024px)');
  const [urlSearchParams] = useSearchParams()
  const { currency } = useSelector((state: any) => state.appReducer)
  const navigate = useNavigate()
  // return <RefreshingResults />

  useEffect(() => {
    if (!urlSearchParams.get('cur')) {
      navigate(`/?cur=${currency?.name}`)
    }
  }, [])

  var schema: any = {
    "@context": "https://schema.org",
    "@type": "TravelAgency",
    "name": "AeroTrav",
    "url": "https://www.aerotrav.com/",
    "logo": " https://www.aerotrav.com/static/media/logo.01dd1ddb3e80947a4e7d.png",
    "description": "Looking for cheap flights? AeroTrav offers the best deals on airline tickets. Book your next flight today and save!",
    "sameAs": [
      "https://www.facebook.com/aerotravus",
      "https://www.instagram.com/aerotravus",
      "https://twitter.com/aerotrav"
    ]

  }

  return (
    <>
      <Helmet>
        <title>Flight booking at lowest price, flight tickets: Aerotrav</title>
        <meta name="description" content="Looking for flight ticket booking at best price? Aerotrav, a one-stop destination for cheap flight tickets booking. Enjoy hassle-free service at best price. Get exciting deals and offers on booking." />
        <meta name="keywords" content="flight booking, flight ticket, flight ticket booking, book your flight, air ticket booking, online flight ticket booking low fare flight, book flight ticket, cheap flight ticket booking, deals on flight booking, Adani One flight booking, flight booking online, cheap flight booking" />
      </Helmet>

      <JsonLd<TravelAgency>
        item={schema}
      />

      <div className="nc-PageHome relative overflow-hidden">
        {/* GLASSMOPHIN */}
        {/* <BgGlassmorphism /> */}

        <div className="relative">
          {/* Section Hero Start */}
          {/* <div className="">
          {!matches &&
            <div className="w-full relative mx-auto flex justify-center">
              <HeroSearchForm isFullWidth={false} />
            </div>
          }
        </div> */}

          {/* hero section */}

          {/* {!matches && */}
          <Hero className="" />
          {/* } */}
          {/* hero section end*/}
          <Suspense fallback={<Loading />} >
            <Features />
            <Destinations />
          </Suspense>

          {/* easy Steps start */}
          <EaseSteps className='' />
          {/* easy Steps end */}

          {/* airline deals start */}
          {/* <AirlineDeals className='' /> */}
          <SectionGridCategoryBox />
          {/* airline deals end */}

          {/* news letter start */}
          <NewsLetter />
          {/* news letter end */}

        </div>
      </div>
    </>
  );
}

export default PageHome;
