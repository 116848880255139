import React,{useState,useEffect} from 'react'
import EaseSteps from 'containers/PageHome/HomeComponents/EasySteps/EaseSteps'
// import Hero from 'containers/PageHome/HomeComponents/Hero/Hero'
import BestOffers from './OfferComponents/BestOffers/BestOffers'
import Features from './OfferComponents/Features/Features'
import ExclusiveDeals from './OfferComponents/ExclusiveDeals/ExclusiveDeals'
import Hero from './OfferComponents/SearchFlight/Hero'
import { doc, getDoc } from 'firebase/firestore'
import { db } from 'config/firebase-config'

const PageOffers = () => {
  // const [offersData,setOffersData]=useState<any>([])
 
//   const getoffersData = async () => {
//     let documentSnapshots;
//     documentSnapshots = await getDoc(
//         doc(db, "Offers", "page")
//     );
//     console.log(documentSnapshots.data(),"data")
//     const data2=[documentSnapshots.data()]
//     console.log(data2,"data2")
//     // return data2
//     // setOffersData(data2)
//     setOffersData(data2)
//     // dispatch(getHomeData(documentSnapshots.data()))
// }

// useEffect(() => {
//     getoffersData()
//     // setOffersData()
// }, [])
  return (
    <div>
        {/* <Hero className="" /> */}
        <Hero/>
        <Features/>
        <ExclusiveDeals />
        <BestOffers/>
        <EaseSteps/>
    </div>
  )
}

export default PageOffers

