const { serverUrl: url } = require('../utils/urls')

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export const getExchangedPrice = async ({ from, to }) => {
    if (from === to) {
        return 1
    }
    const res = await fetch(`${url}/curr-ex-rate`, { method: 'POST', headers: headers, body: JSON.stringify({ from: from, to: to }) })
    const data = await res.json()
    return data.value
}
