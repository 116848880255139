import React from "react";
import ReactDOM from "react-dom";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider } from "react-redux";
import { store } from "app/store";
import './assets/flaticons/flaticon_mycollection.css'

const queryClient = new QueryClient()


// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );



const rootElement: any = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
    , rootElement);
} else {
  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
    , rootElement);
}


// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <QueryClientProvider client={queryClient}>
//       <App />
//       <ReactQueryDevtools initialIsOpen={false} />
//     </QueryClientProvider>
//   </Provider>
//   // </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
