import { useQuery } from '@tanstack/react-query'
import { db } from 'config/firebase-config'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet"
import Loading from 'shared/Loading/Loading'
import { BsClock } from 'react-icons/bs'
import moment from 'moment'

const SingleBlog = () => {
    const { slug }: any = useParams()
    const getBlogData = async () => {
        const querySnap = await getDocs(query(collection(db, "Blogs"), where("slug", "array-contains", slug)));
        const arr: any = []
        querySnap.forEach((blog: any) => {
            arr.push({ ...blog.data(), id: blog.id })
        })
        return arr[0]
    }
    const { data: blog, loading }: any = useQuery(['blogData'], getBlogData)

    if (blog)
        return (
            <div className="overflow-hidden relative">
                <Helmet>
                    <title>{blog?.title + " | Bookingglobals"}</title>
                </Helmet>
                <div className='max-h-[60vh] lg:max-h-[80vh] w-full relative overflow-hidden'>
                    <img src={blog?.imgUrl} alt={blog?.title} className='w-full' loading='lazy' />
                    <div className='absolute ml-[3.5%] bottom-[30px]'>
                        <div className='text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-black text-white capitalize drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]'>{blog?.title}</div>
                        <div className='my-[20px] text-sm sm:text-base xl:text-lg flex items-center gap-[10px] text-white drop-shadow-[0_1.5px_1.5px_rgba(0,0,0,0.8)]'>
                            <span>{blog?.author}</span>
                            <span className='flex items-center gap-[5px]'><BsClock />{moment(blog?.createdAt?.toDate()).format("MMM DD, YYYY")}</span>
                        </div>
                    </div>
                </div>
                <div className='w-[93%] mx-auto my-[50px] bg-white text-sm sm:text-base 2xl:text-lg'>
                    <div dangerouslySetInnerHTML={{ __html: blog?.content }}></div>
                    <div className='mt-[20px]'>
                        <div className='text-2xl font-bold'>Description</div>
                        <div dangerouslySetInnerHTML={{ __html: blog?.description }}></div>
                    </div>
                </div>
            </div>
        )
    else return <Loading />
}

export default SingleBlog